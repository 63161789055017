import { api } from "@/api";


export default {
    state: {
        dictionariesNsiList: [],
        dictionariesNsiDepartmentsList: [],
        dictionariesNsiOrganizationsList: [],
        dictionariesNsiPositionsList: [],
        dictionariesNsiPaymentList: [],
        dictionariesNsiPlacementList: [],
        dictionariesNsiInsuranceList: [],
        dictionariesNsiSubjectsList: [],
        isDictionariesNsiLoading: false,
        dictionariesNsiDepartmentsListTotalPages: null,
        dictionariesNsiDepartmentsListPage: 1,
        dictionariesNsiDepartmentsListPageSize: 50,
    },
    actions: {
        async fetchDictionariesNsiList({ dispatch, commit }, queryParams) {
            commit("upadeDictionariesNsiListLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const nsi = await api.getDictionariesNsi(queryParams, token);
                    commit("changeDictionariesNsiList", nsi.results);
                    commit("upadeDictionariesNsiListLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeDictionariesNsiListLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
                commit("upadeDictionariesNsiListLoader", false);
            }
        },
        async fetchMoreDictionariesNsi({ dispatch, commit }, queryParams) {
            commit("upadeDictionariesNsiListLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const nsi = await api.getDictionariesNsi(queryParams, token);
                    commit("updateMoreDictionariesNsi", nsi.results);
                    commit("upadeDictionariesNsiListLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeDictionariesNsiListLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("upadeDictionariesNsiListLoader", false);
            }

        },
        clearDictionariesNsiList({ commit }) {
            commit("changeDictionariesNsiList", []);
        },

        async fetchDictionariesNsiDepartmentsList({ dispatch, commit }, queryParams) {
            commit("upadeDictionariesNsiListLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const departments = await api.getDictionariesDepartments(queryParams, token);
                    commit("changeDictionariesNsiDepartmentsList", departments.results);
                    commit("changeDictionariesNsiDepartmentsListTotalPages", departments.total_pages);
                    commit("upadeDictionariesNsiListLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeDictionariesNsiListLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
                commit("upadeDictionariesNsiListLoader", false);
            }
        },
        async fetchMoreDictionariesNsiDepartments({ dispatch, commit }, queryParams) {
            commit("upadeDictionariesNsiListLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const departments = await api.getDictionariesDepartments(queryParams, token);
                    commit("updateMoreDictionariesNsiDepartments", departments.results);
                    commit("upadeDictionariesNsiListLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeDictionariesNsiListLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("upadeDictionariesNsiListLoader", false);
            }

        },
        clearDictionariesNsiDepartmentsList({ commit }) {
            commit("changeDictionariesNsiDepartmentsList", []);
        },

        async fetchDictionariesNsiOrganizationsList({ dispatch, commit }, queryParams) {
            commit("upadeDictionariesNsiListLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const organizations = await api.getDictionariesOrganizations(queryParams, token);
                    console.log(organizations);
                    commit("changeDictionariesNsiOrganizationsList", organizations.results);
                    commit("upadeDictionariesNsiListLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeDictionariesNsiListLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
                commit("upadeDictionariesNsiListLoader", false);
            }
        },
        async fetchMoreDictionariesNsiOrganizations({ dispatch, commit }, queryParams) {
            commit("upadeDictionariesNsiListLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const organizations = await api.getDictionariesOrganizations(queryParams, token);
                    commit("updateMoreDictionariesNsiOrganizations", organizations.results);
                    commit("upadeDictionariesNsiListLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeDictionariesNsiListLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("upadeDictionariesNsiListLoader", false);
            }

        },
        clearDictionariesNsiOrganizationsList({ commit }) {
            commit("changeDictionariesNsiOrganizationsList", []);
        },

        async fetchDictionariesNsiPositionsList({ dispatch, commit }, queryParams) {
            commit("upadeDictionariesNsiListLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const positions = await api.getDictionariesPositions(queryParams, token);
                    commit("changeDictionariesNsiPositionsList", positions.results);
                    commit("upadeDictionariesNsiListLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeDictionariesNsiListLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
                commit("upadeDictionariesNsiListLoader", false);
            }
        },
        async fetchMoreDictionariesNsiPositions({ dispatch, commit }, queryParams) {
            commit("upadeDictionariesNsiListLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const positions = await api.getDictionariesPositions(queryParams, token);
                    commit("updateMoreDictionariesNsiPositions", positions.results);
                    commit("upadeDictionariesNsiListLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeDictionariesNsiListLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("upadeDictionariesNsiListLoader", false);
            }

        },
        async fetchDictionariesNsiPaymentList({ dispatch, commit }, queryParams) {
            commit("upadeDictionariesNsiListLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const nsi = await api.getDictionariesPayment(queryParams, token);
                    commit("changeDictionariesNsiPaymentList", nsi.results);
                    commit("upadeDictionariesNsiListLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeDictionariesNsiListLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
                commit("upadeDictionariesNsiListLoader", false);
            }
        },
        async fetchDictionariesNsiPlacementList({ dispatch, commit }, queryParams) {
            commit("upadeDictionariesNsiListLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const nsi = await api.getDictionariesPlacement(queryParams, token);
                    commit("changeDictionariesNsiPlacementList", nsi.results);
                    commit("upadeDictionariesNsiListLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeDictionariesNsiListLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
                commit("upadeDictionariesNsiListLoader", false);
            }
        },
        async fetchDictionariesNsiInsuranceList({ dispatch, commit }, queryParams) {
            commit("upadeDictionariesNsiListLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const nsi = await api.getDictionariesInsurance(queryParams, token);
                    commit("changeDictionariesNsiInsuranceList", nsi.results);
                    commit("upadeDictionariesNsiListLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeDictionariesNsiListLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
                commit("upadeDictionariesNsiListLoader", false);
            }
        },
        async fetchDictionariesNsiSubjectsList({ dispatch, commit }, queryParams) {
            commit("upadeDictionariesNsiListLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const nsi = await api.getDictionariesSubject(queryParams, token);
                    commit("changeDictionariesNsiSubjectsList", nsi.results);
                    commit("upadeDictionariesNsiListLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeDictionariesNsiListLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
                commit("upadeDictionariesNsiListLoader", false);
            }
        },
        clearDictionariesNsiPositionsList({ commit }) {
            commit("changeDictionariesNsiPositionsList", []);
        },
        updateDictionariesNsiDepartmentsListPage({ commit }, dictionariesNsiDepartmentsListPage) {
            commit("changeDictionariesNsiDepartmentsListPage", dictionariesNsiDepartmentsListPage);
        },
        updateDictionariesNsiDepartmentsListPageSize({ commit }, dictionariesNsiDepartmentsListPageSize) {
            commit("changeDictionariesNsiDepartmentsListPageSize", dictionariesNsiDepartmentsListPageSize);
        },
        addDepartmentInNsiDepartmentsList({ commit }, department) {
            commit("changeAddDepartmentInNsiDepartmentsList", department);
        },
        editDepartmentInNsiDepartmentsList({ commit }, [department, index]) {
            commit("changeEditDepartmentInNsiDepartmentsList", [department, index]);
        },
        deleteDepartmentInNsiDepartmentsList({ commit }, index) {
            commit("changeDeleteDepartmentFromArray", index);
        },
    },
    mutations: {
        changeDictionariesNsiList(state, dictionariesNsiList) {
            state.dictionariesNsiList = dictionariesNsiList;
        },
        updateMoreDictionariesNsi(state, dictionariesNsiList) {
            state.dictionariesNsiList = [...state.dictionariesNsiList, ...dictionariesNsiList];
        },

        changeDictionariesNsiDepartmentsList(state, dictionariesNsiDepartmentsList) {
            state.dictionariesNsiDepartmentsList = dictionariesNsiDepartmentsList;
        },
        changeAddDepartmentInNsiDepartmentsList(state, department) {
            state.dictionariesNsiDepartmentsList.unshift(department);
        },
        changeEditDepartmentInNsiDepartmentsList(state, [department, index]) {
            state.dictionariesNsiDepartmentsList.splice(index, 1, department);
        },
        changeDeleteDepartmentFromArray(state, index) {
            state.dictionariesNsiDepartmentsList.splice(index, 1);
        },
        updateMoreDictionariesNsiDepartments(state, dictionariesNsiDepartmentsList) {
            state.dictionariesNsiDepartmentsList = [...state.dictionariesNsiDepartmentsList, ...dictionariesNsiDepartmentsList];
        },
        changeDictionariesNsiDepartmentsListTotalPages(state, dictionariesNsiDepartmentsListTotalPages) {
            state.dictionariesNsiDepartmentsListTotalPages = dictionariesNsiDepartmentsListTotalPages;
        },
        changeDictionariesNsiDepartmentsListPage(state, dictionariesNsiDepartmentsListPage) {
            state.dictionariesNsiDepartmentsListPage = dictionariesNsiDepartmentsListPage;
        },
        changeDictionariesNsiDepartmentsListPageSize(state, dictionariesNsiDepartmentsListPageSize) {
            state.dictionariesNsiDepartmentsListPageSize = dictionariesNsiDepartmentsListPageSize;
        },

        changeDictionariesNsiOrganizationsList(state, dictionariesNsiOrganizationsList) {
            state.dictionariesNsiOrganizationsList = dictionariesNsiOrganizationsList;
        },
        updateMoreDictionariesNsiOrganizations(state, dictionariesNsiOrganizationsList) {
            state.dictionariesNsiOrganizationsList = [...state.dictionariesNsiOrganizationsList, ...dictionariesNsiOrganizationsList];
        },

        changeDictionariesNsiPositionsList(state, dictionariesNsiPositionsList) {
            state.dictionariesNsiPositionsList = dictionariesNsiPositionsList;
        },
        updateMoreDictionariesNsiPositions(state, dictionariesNsiPositionsList) {
            state.dictionariesNsiPositionsList = [...state.dictionariesNsiPositionsList, ...dictionariesNsiPositionsList];
        },

        changeDictionariesNsiPaymentList(state, dictionariesNsiPaymentList) {
            state.dictionariesNsiPaymentList = dictionariesNsiPaymentList;
        },
        changeDictionariesNsiPlacementList(state, dictionariesNsiPlacementList) {
            state.dictionariesNsiPlacementList = dictionariesNsiPlacementList;
        },
        changeDictionariesNsiInsuranceList(state, dictionariesNsiInsuranceList) {
            state.dictionariesNsiInsuranceList = dictionariesNsiInsuranceList;
        },
        changeDictionariesNsiSubjectsList(state, dictionariesNsiSubjectsList) {
            state.dictionariesNsiSubjectsList = dictionariesNsiSubjectsList;
        },

        upadeDictionariesNsiListLoader(state, condition) {
            state.isDictionariesNsiLoading = condition;
        },
    },
    getters: {
        getDictionariesNsiList(state) {
            return state.dictionariesNsiList;
        },
        getDictionariesNsiDepartmentsList(state) {
            return state.dictionariesNsiDepartmentsList;
        },
        getDictionariesNsiOrganizationsList(state) {
            return state.dictionariesNsiOrganizationsList;
        },
        getDictionariesNsiPositionsList(state) {
            return state.dictionariesNsiPositionsList;
        },
        getDictionariesNsiLoadingStatus(state) {
            return state.isDictionariesNsiLoading;
        },
        getDictionariesNsiDepartmentsListTotalPages(state) {
            return state.dictionariesNsiDepartmentsListTotalPages;
        },
        getDictionariesNsiDepartmentsListPage(state) {
            return state.dictionariesNsiDepartmentsListPage;
        },
        getDictionariesNsiDepartmentsListPageSize(state) {
            return state.dictionariesNsiDepartmentsListPageSize;
        },
        getDictionariesNsiPaymentList(state) {
            return state.dictionariesNsiPaymentList;
        },
        getDictionariesNsiPlacementList(state) {
            return state.dictionariesNsiPlacementList;
        },
        getDictionariesNsiInsuranceList(state) {
            return state.dictionariesNsiInsuranceList;
        },
        getDictionariesNsiSubjectsList(state) {
            return state.dictionariesNsiSubjectsList;
        },
    }
}