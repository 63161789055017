export const lifetimeRegistrationInformationFields = {
    data() {
        return {
            lifetimeInformationDescriptionFields:{
                register_timestamp: ["Дата и время регистрации:", "changeTimezoneDateTimeFormat"],
                responsible_staff_name: ["Ответственный сотрудник:", "checkingStringData"]
            },
            lifetimeInformationFormDescriptionFields:{
                responsible_staff_name: ["Ответственный сотрудник:", ["row__small"]]
            },
        }
    }
}