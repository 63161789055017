import moment from "moment";
import momentTimezone from 'moment-timezone';
import { mapGetters } from "vuex";


export const changeDateFormatConst = {
    data() {
        return {
            maxDateTimeLimitField: null,
        }
    },
    computed: {
        ...mapGetters(["getCurrentEmployeeDepartment"]),
        changeTimezoneDateTimeFormat() {
            return (item) => {
                if (!item) return "";
                const formatString = "DD.MM.YYYY HH:mm";
                return momentTimezone(item)
                    .tz(this.getCurrentEmployeeDepartment?.time_zone || "Europe/Moscow")
                    .format(formatString);
            };
        },
        changeTimezoneDateFormat() {
            return (item) => {
                if (!item) return "";
                const formatString = "DD.MM.YYYY";
                return momentTimezone(item)
                    .tz(this.getCurrentEmployeeDepartment?.time_zone || "Europe/Moscow")
                    .format(formatString);
            };
        },
        changeTimeFormat() {
            return (item) => {
                if ((item === null) || (item === undefined) || (item.length === 0)) {
                    return "";
                }
                return moment(item, "HH:mm:ssZ").format("HH:mm");
            }
        },
        changeDateTimeFormatInput() {
            return (item) => {
                if ((item === null) || (item === undefined) || (item.length === 0)) {
                    return "";
                }
                return moment(item).format("YYYY-MM-DD HH:mm");
            }
        },
        changeTimeFormatTz() {
            return (item) => {
                if ((item === null) || (item === undefined) || (item.length === 0)) {
                    return null;
                }
                return moment(item, "HH:mm").format("HH:mm:ssZ");
            }
        },
        changeDateTimeFormatTz() {
            return (item) => {
                if (!item) return null;
                const utcDate = moment.tz(item, this.getCurrentEmployeeDepartment?.time_zone).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
                return utcDate; // Преобразование в UTC
            };
        },
        changeDateFormatTz() {
            return (item) => {
                if (!item) return null;
                const utcDate = moment.tz(item, this.getCurrentEmployeeDepartment?.time_zone).format("YYYY-MM-DD");
                return utcDate; // Преобразование в UTC
            };
        },
        getMinDateLimit() {
            return this.changeDateTimeFormatTz(moment().date(1).subtract(3, "month"));
        },
        getMaxDateLimit() {
            return this.changeDateTimeFormatTz(moment());
        },
        getMaxDateTimeLimit() {
            return this.changeDateTimeFormatInput(moment());
        },
    },
    methods: {
        updateMaxDateTimeLimitField() {
            return this.maxDateTimeLimitField = this.changeDateTimeFormatInput(moment());
        },
    },
    mounted() {
        this.updateMaxDateTimeLimitField();
    }
};