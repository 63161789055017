<template>
    <Dialog
        v-model:visible="getShowModalStatus"
        modal
        header="Header"
        class="dialog"
        :closable="false"
        :pt="{
            headericons: {
                style: 'display: none;'
            }
        }"
    >
        <template #header>
            <div class="dialog__header">
                <span>Выбор должности</span>
            </div>
        </template>
        <template #closeicon>
            <div style="display: none;"></div>
        </template>
        <div class="dialog__body" style="display: flex; flex-direction: column; gap: 12px;">
            <div style="width: 100%;">
                <AppDropdown
                    :selectOptionList="getProfileData.employee.employee_positions"
                    :dropdownLabel="'Выбор должности'"
                    :optionLabel="'position_name'"
                    :defaultValue="getCurrentEmployeePosition"
                    dontShowClearButton
                    @updateDropdownItem="(e) => selectedPosition = e"
                />
            </div>
        </div>
        <template #footer>
            <Button
                class="button__purple_border"
                label="Отменить"
                @click="closeDialog()"
            />
            <Button
                class="button__purple"
                style="flex-direction: row-reverse;"
                label="Продолжить"
                @click="changePosition()"
            >
                <template #icon>
                    <i  
                        class="button__icon_white"
                        style="padding-top: 6px;"
                        v-html="getIconTemplate('icon-angle-double-small-right')"
                    ></i>
                </template>
            </Button>
        </template>
    </Dialog>
</template>

<script>
    import { api } from "@/api";
    import { mapActions, mapGetters } from "vuex";
    import { icons } from "@/mixins";
    import AppDropdown from "@/components/AppDropdown.vue";
    export default {
        name: "ChangePositionDialog",
        mixins: [icons],
        emits: ["closeDialog"],
        props: {
            isShowChangePositionDialog: Boolean,
        },
        data() {
            return {
                selectedPosition: null,
                getShowModalStatus:false,
            }
        },
        components: {
            AppDropdown,
        },
        computed: {
            ...mapGetters([
                "getProfileData",
            ]),
            getCurrentEmployeePosition() {
                const currentPositionId = JSON.parse(localStorage.getItem('employeePositionId'));
                let currentPosition;
                this.getProfileData?.employee?.employee_positions.forEach((position) => {
                    if (position?.id === currentPositionId) {
                        currentPosition = position;
                    }
                })
                return currentPosition;
            }
        },
        methods: {
            ...mapActions([
                "fetchAccessToken",
                "fetchProfileData",
                "getLifetimeCases",
                "fetchSamplePreparationCassettes"
            ]),
            closeDialog() {
                this.$emit("closeDialog");
                this.selectedPosition = null;
            },
            async changePosition() {
                const token = await this.fetchAccessToken();
                try {
                    const employeePositionsData = {
                        employee_position: this.selectedPosition.id
                    };
                    const userSession = await api.postUserSession(employeePositionsData, token);
                    if (userSession.id) {
                        localStorage.setItem('employeePositionId', JSON.stringify(userSession.employee_position));
                        this.closeDialog();
                        this.fetchProfileData();
                        if (this.$route.name === "lifetimeCases") {
                            this.getLifetimeCases();
                        } else if (this.$route.name === "lifetimeCasesSamplePreparation") {
                            const queryParams = {
                                page: 1,
                                page_size: 50,
                                exclude_cito_lifecases: true,
                                exclude_materials_with_related_lifecases_with_external_depts: true
                            };
                            this.fetchSamplePreparationCassettes([queryParams]);
                        }
                    }
                } catch (error) {
                    console.log('%cError: ', 'color: green;', error);
                }
            },
        },
        watch: {
            isShowChangePositionDialog() {
                this.getShowModalStatus = this.isShowChangePositionDialog;
            },
        },
    }
</script>