import { lifetimeCasesTabsApi } from "@/api";

export default {
    state: {
        lifetimeMaterialAddBtn: false,
        lifetimeBiopsySamplesFlasks: [],
        lifetimeBiopsySamplesFlasksServer: [],
        lifetimeBiopsySamplesFlasksItemById: {},
        editLifetimeBiopsySamplesFlasksItemId: null,
        isMaterialFormActionConfirmationShowStatus: false,
        lifetimeBiopsySamplesForm: null,
        lifetimeBiopsySamplesData: {},
    },
    actions: {
        updateLifetimeBiopsySamplesForm({ commit }, lifetimeBiopsySamplesForm) {
            commit("changeLifetimeBiopsySamplesForm", lifetimeBiopsySamplesForm);
        },
        updateLifetimeBiopsySamplesFlasks({ commit, dispatch }, [lifetimeBiopsySamplesFlasks, status]) {
            switch (status) {
                case "add":
                    commit("addLifetimeBiopsySamplesFlasks", lifetimeBiopsySamplesFlasks);
                    dispatch("updateLifetimeBiopsySamplesFlasksServer");
                    return;
                case "edit":
                    commit("editLifetimeBiopsySamplesFlasks", lifetimeBiopsySamplesFlasks);
                    dispatch("updateLifetimeBiopsySamplesFlasksServer");
                    return;
                case "delete":
                    commit("deleteItemFromLifetimeBiopsySamplesFlasks", lifetimeBiopsySamplesFlasks);
                    commit("reassignAllFlaskNumbers");
                    dispatch("updateLifetimeBiopsySamplesFlasksServer");
                    return;
                default:
                    commit("changeLifetimeBiopsySamplesFlasks", lifetimeBiopsySamplesFlasks);
                    dispatch("updateLifetimeBiopsySamplesFlasksServer");
                    return;
            }
        },
        async updateLifetimeBiopsySamplesFlasksById({ dispatch }, updatedBiopsySamplesFlasksData) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                dispatch("fetchLifetimeBiopsySamplesFlasksById", [token, updatedBiopsySamplesFlasksData]);
            });
        },
        async fetchLifetimeBiopsySamplesFlasksById({ dispatch, rootGetters }, [token, updatedBiopsySamplesFlasksData]) {
            try {
                const biopsySamplesFlasksData = await lifetimeCasesTabsApi.putLifetimeCaseBiopsyById(rootGetters.getLifetimeCaseId, updatedBiopsySamplesFlasksData, token);
                if (typeof biopsySamplesFlasksData === "number") {
                    return;
                }
                if (biopsySamplesFlasksData) {
                    dispatch("addToastMessage", {code: 200, message: null});
                    dispatch("fetchLifetimeCaseById", [token, rootGetters.getLifetimeCaseId]);
                }
            } catch (e) {
                console.error(e);
            }
        },
        updateLifetimeMaterialAddBtn({ commit }, status) {
            commit("changeLifetimeMaterialAddBtn", status);
        },
        updateLifetimeBiopsySamplesFlasksItemById({ commit }, id) {
            commit("changeLifetimeBiopsySamplesFlasksItemById", id);
        },
        updateEditLifetimeBiopsySamplesFlasksItemId({ commit }, id) {
            commit("changeEditLifetimeBiopsySamplesFlasksItemId", id);
        },
        updateLifetimeBiopsySamplesData({ commit }, lifetimeBiopsySamplesData) {
            commit("changeLifetimeBiopsySamplesData", lifetimeBiopsySamplesData);
        },
        updateIsMaterialFormActionConfirmationShowStatus({ commit }, isMaterialFormActionConfirmationShowStatus) {
            commit("changeIsMaterialFormActionConfirmationShowStatus", isMaterialFormActionConfirmationShowStatus);
        },
        updateLifetimeBiopsySamplesFlasksServer({ commit, state }) {
            const newFlasks = JSON.parse(JSON.stringify(state.lifetimeBiopsySamplesFlasks));
            const flasks = newFlasks.map((flask) => {
                return {
                    ...(flask.id ? { id: flask.id } : {}),
                    number: flask.number,
                    localization_id: flask?.localization?.id,
                    form_id: flask?.form?.id,
                    position_id: flask?.position?.id,
                    characteristic: flask?.characteristic,
                    pieces_count: flask?.pieces_count,
                }
            });
            commit("changeLifetimeBiopsySamplesFlasksServer", flasks);
        },
        clearLifetimeMaterialData({ commit }) {
            commit("changeLifetimeMaterialAddBtn", false);
            commit("changeLifetimeBiopsySamplesForm", null);
            commit("changeLifetimeBiopsySamplesData", {});
            commit("changeIsMaterialFormActionConfirmationShowStatus", false);
            commit("clearLifetimeBiopsySamplesFlasksItemById");
            commit("changeEditLifetimeBiopsySamplesFlasksItemId", null);
            commit("changeLifetimeBiopsySamplesFlasks", []);
            commit("changeLifetimeBiopsySamplesFlasksServer", []);
        },
    },
    mutations: {
        changeLifetimeMaterialAddBtn(state, status) {
            state.lifetimeMaterialAddBtn = status;
        },
        changeLifetimeBiopsySamplesForm(state, lifetimeBiopsySamplesForm) {
            state.lifetimeBiopsySamplesForm = lifetimeBiopsySamplesForm;
        },
        addLifetimeBiopsySamplesFlasks(state, lifetimeBiopsySamplesFlasks) {
            state.lifetimeBiopsySamplesFlasks = [...state.lifetimeBiopsySamplesFlasks, lifetimeBiopsySamplesFlasks];
        },
        changeLifetimeBiopsySamplesFlasks(state, lifetimeBiopsySamplesFlasks) {
            state.lifetimeBiopsySamplesFlasks = lifetimeBiopsySamplesFlasks;
        },
        editLifetimeBiopsySamplesFlasks(state, lifetimeBiopsySamplesFlasks) {
            const editIndexOfFlaskArray = state.lifetimeBiopsySamplesFlasks.indexOf(state.lifetimeBiopsySamplesFlasksItemById);
            state.lifetimeBiopsySamplesFlasks[editIndexOfFlaskArray] = lifetimeBiopsySamplesFlasks;
            // state.lifetimeBiopsySamplesFlasks.splice(state.editLifetimeBiopsySamplesFlasksItemId, 1, lifetimeBiopsySamplesFlasks);
        },
        deleteItemFromLifetimeBiopsySamplesFlasks(state, id) {
            state.lifetimeBiopsySamplesFlasks = state.lifetimeBiopsySamplesFlasks.filter((item, index) => index !== id);
            // state.lifetimeBiopsySamplesFlasks.splice(id, 1);
        },
        reassignAllFlaskNumbers(state) {
            state.lifetimeBiopsySamplesFlasks.map((flask, index) => {
                flask.number = index + 1;
                return flask;
            });
        },
        changeLifetimeBiopsySamplesFlasksItemById(state, id) {
            let item = state.lifetimeBiopsySamplesFlasks.filter((item, index) => {
                if (item.id === id || index === id) {
                    return item;
                }
            });
            state.lifetimeBiopsySamplesFlasksItemById = item[0];
        },
        clearLifetimeBiopsySamplesFlasksItemById(state) {
            state.lifetimeBiopsySamplesFlasksItemById = {};
        },
        changeEditLifetimeBiopsySamplesFlasksItemId(state, id) {
            state.editLifetimeBiopsySamplesFlasksItemId = id;
        },
        changeLifetimeBiopsySamplesData(state, lifetimeBiopsySamplesData) {
            state.lifetimeBiopsySamplesData = lifetimeBiopsySamplesData;
        },
        changeIsMaterialFormActionConfirmationShowStatus(state, isMaterialFormActionConfirmationShowStatus) {
            state.isMaterialFormActionConfirmationShowStatus = isMaterialFormActionConfirmationShowStatus;
        },
        changeLifetimeBiopsySamplesFlasksServer(state, flasks) {
            state.lifetimeBiopsySamplesFlasksServer = flasks;
        },
    },
    getters: {
        getLifetimeMaterialAddBtn(state) {
            return state.lifetimeMaterialAddBtn;
        },
        getLifetimeBiopsySamplesFlasks(state) {
            return state.lifetimeBiopsySamplesFlasks;
        },
        getLifetimeBiopsySamplesFlasksItemById(state) {
            return state.lifetimeBiopsySamplesFlasksItemById;
        },
        getEditLifetimeBiopsySamplesFlasksItemId(state) {
            return state.editLifetimeBiopsySamplesFlasksItemId;
        },
        getLifetimeBiopsySamplesData(state) {
            return state.lifetimeBiopsySamplesData;
        },
        getIsMaterialFormActionConfirmationShowStatus(state) {
            return state.isMaterialFormActionConfirmationShowStatus;
        },
        getLifetimeBiopsySamplesFlasksServer(state) {
            return state.lifetimeBiopsySamplesFlasksServer;
        },
        getLifetimeBiopsySamplesForm(state) {
            return state.lifetimeBiopsySamplesForm;
        },
    }
}