import { lifetimeApi } from "@/api";

export default {
    state: {
        samplePreparationsFiltersButtonStatus: false,
        samplePreparationsFiltersData: {},
        samplePreparationStageFilter: null,
        samplePreparationTypeFilter: null,
        samplePreparationSelectedCassette: [],
        samplePreparationSelectedMicro: [],
        samplePreparationCassettes: [],
        samplePreparationTotalPages: null,
        samplePreparationTotalCount: null,
        isSamplePreparationCassettesLoading: false,
    },
    actions: {
        async fetchSamplePreparationCassettes({ dispatch, commit }, [queryParams, isFetchByWebSocket = false]) {
            if (!isFetchByWebSocket) {
                commit("updateSamplePreparationCassettesLoader", true);
            }
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const samplePreparationCassettes = await lifetimeApi.getSamplePreparationCassette(queryParams, token);
                    if (typeof samplePreparationCassettes === "object") {
                        commit("updateSamplePreparationCassettes", samplePreparationCassettes.results);
                        commit("updateSamplePreparationTotalPages", samplePreparationCassettes.total_pages);
                        commit("updateSamplePreparationTotalCount", samplePreparationCassettes.count);
                        commit("updateSamplePreparationCassettesLoader", false);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSamplePreparationCassettesLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSamplePreparationCassettesLoader", false);
            }

        },
        async fetchMoreSamplePreparationCassettes({ dispatch, commit }, queryParams) {
            commit("updateSamplePreparationCassettesLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const samplePreparationCassettes = await lifetimeApi.getSamplePreparationCassette(queryParams, token);
                    if (typeof samplePreparationCassettes === "object") {
                        commit("updateMoreSamplePreparationCassettes", samplePreparationCassettes.results);
                        commit("updateSamplePreparationCassettesLoader", false);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSamplePreparationCassettesLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSamplePreparationCassettesLoader", false);
            }

        },
        updateSamplePreparationStageFilter({ commit }, samplePreparationStageFilter) {
            commit("changeSamplePreparationStageFilter", samplePreparationStageFilter);
        },
        updateSamplePreparationTypeFilter({ commit }, samplePreparationTypeFilter) {
            commit("changeSamplePreparationTypeFilter", samplePreparationTypeFilter);
        },
        updateSamplePreparationsFiltersButtonStatus({ commit }, samplePreparationsFiltersButtonStatus) {
            commit("changeSamplePreparationsFiltersButtonStatus", samplePreparationsFiltersButtonStatus);
        },
        updateSamplePreparationsFiltersData({ commit }, samplePreparationsFiltersData) {
            commit("changeSamplePreparationsFiltersData", samplePreparationsFiltersData);
        },
        updateSamplePreparationSelectedCassette({ commit }, samplePreparationSelectedCassette) {
            commit("changeSamplePreparationSelectedCassette", samplePreparationSelectedCassette);
        },
        updateSamplePreparationSelectedMicro({ commit }, samplePreparationSelectedMicro) {
            commit("changeSamplePreparationSelectedMicro", samplePreparationSelectedMicro);
        },
    },
    mutations: {
        updateSamplePreparationCassettes(state, samplePreparationCassettes) {
            state.samplePreparationCassettes = samplePreparationCassettes;
        },
        updateSamplePreparationCassettesLoader(state, condition) {
            state.isSamplePreparationCassettesLoading = condition;
        },
        updateMoreSamplePreparationCassettes(state, samplePreparationCassettes) {
            state.samplePreparationCassettes = [...state.samplePreparationCassettes, ...samplePreparationCassettes];
        },
        updateSamplePreparationTotalPages(state, samplePreparationTotalPages) {
            state.samplePreparationTotalPages = samplePreparationTotalPages;
        },
        updateSamplePreparationTotalCount(state, samplePreparationTotalCount) {
            state.samplePreparationTotalCount = samplePreparationTotalCount;
        },
        changeSamplePreparationStageFilter(state, samplePreparationStageFilter) {
            state.samplePreparationStageFilter = samplePreparationStageFilter;
        },
        changeSamplePreparationTypeFilter(state, samplePreparationTypeFilter) {
            state.samplePreparationTypeFilter = samplePreparationTypeFilter;
        },
        changeSamplePreparationsFiltersButtonStatus(state, samplePreparationsFiltersButtonStatus) {
            state.samplePreparationsFiltersButtonStatus = samplePreparationsFiltersButtonStatus;
        },
        changeSamplePreparationsFiltersData(state, samplePreparationsFiltersData) {
            state.samplePreparationsFiltersData = samplePreparationsFiltersData;
        },
        changeSamplePreparationSelectedCassette(state, samplePreparationSelectedCassette) {
            state.samplePreparationSelectedCassette = samplePreparationSelectedCassette;
        },
        changeSamplePreparationSelectedMicro(state, samplePreparationSelectedMicro) {
            state.samplePreparationSelectedMicro = samplePreparationSelectedMicro;
        },
    },
    getters: {
        getSamplePreparationCassettes(state) {
            return state.samplePreparationCassettes;
        },
        getSamplePreparationLoaderStatus(state) {
            return state.isSamplePreparationCassettesLoading;
        },
        getSamplePreparationTotalPages(state) {
            return state.samplePreparationTotalPages;
        },
        getSamplePreparationTotalCount(state) {
            return state.samplePreparationTotalCount;
        },
        getSamplePreparationStageFilter(state) {
            return state.samplePreparationStageFilter;
        },
        getSamplePreparationTypeFilter(state) {
            return state.samplePreparationTypeFilter;
        },
        getSamplePreparationsFiltersButtonStatus(state) {
            return state.samplePreparationsFiltersButtonStatus;
        },
        getSamplePreparationsFiltersData(state) {
            return state.samplePreparationsFiltersData;
        },
        getSamplePreparationSelectedCassette(state) {
            return state.samplePreparationSelectedCassette;
        },
        getSamplePreparationSelectedMicro(state) {
            return state.samplePreparationSelectedMicro;
        },
    }
}