import { api } from "@/api";
import { redirectAuth } from "@/helpers/redirect";

export default {
    state: {
        errorStatus: "",
        authorizationData: {},
        loginFieldFilledInStatus: false,
        passwordFieldFilledInStatus: false,
        authorizationLoginButtonDisabledStatus: false,
        token: null,
    },

    actions: {
        getRedirectAuthAfterInvalidRefreshTokenAction() {
            // dispatch("updateInvalidRefreshTokenAndContainerShowStatus", true);
            redirectAuth();
        },

        removeAuthDataFromStorage({ dispatch }) {
            localStorage.removeItem("vuex");
            localStorage.removeItem("accessTokenData");
            localStorage.removeItem("userData");
            localStorage.removeItem("isAuthorizedUser");
            document.cookie = `refreshTokenData=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
            dispatch("resetMenuStoreState");
        },

        async getTokenData({ dispatch, commit, state }) {
            try {
                dispatch("updateIsComponentLoaderButtonActive", true);
                commit("changeAuthorizationLoginButtonDisabledStatus", true);
                const token = await api.getToken(state.authorizationData);
                if (token.user.id) {
                    localStorage.setItem("userData", JSON.stringify(token.user.id));
                }
                if (localStorage.accessTokenData === undefined) {
                    dispatch("logOut");
                    commit("changeErrorStatus", token);
                    dispatch("updateIsComponentLoaderButtonActive", false);
                    return;
                }
                commit("changeErrorStatus", "");
                dispatch("updateAuthorizationData", {});
                dispatch("updateIsComponentLoaderButtonActive", false);
                commit("changeAuthorizationLoginButtonDisabledStatus", false);
                return token;
            } catch (e) {
                // dispatch("setExceptionAccordingResponseCode", e.status);
                dispatch("logOut");
                commit("changeErrorStatus", e.status);
                console.error(e);
                return e;
            }
        },
        async fetchAccessToken({ dispatch }) {
            const token = await dispatch("getAccessToken");
            if (token === undefined) {
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                return;
            }
            return token;
        },
        async getAccessToken({ dispatch }) {
            const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)refreshTokenData\s*=\s*([^;]*).*$)|^.*$/, "$1");
            if (!cookieValue) {
                dispatch("removeAuthDataFromStorage");
                return;
            }
            try {
                const decodedCookieValue = decodeURIComponent(cookieValue);
                const refreshToken = JSON.parse(decodedCookieValue)?.refresh;
                if (!refreshToken) {
                    dispatch("removeAuthDataFromStorage");
                    return;
                }
                const accessTokenData = localStorage.accessTokenData;
                if (!accessTokenData) {
                    // Handle the case where accessTokenData is not available
                    return;
                }
                const dieDateAccessToken = JSON.parse(accessTokenData).access_lifetime + (Date.now() * 1000);
                if (Date.now() >= Math.ceil((dieDateAccessToken - 1000) / 1000)) {
                    const newToken = await dispatch("updateAccessToken");
                    return newToken;
                }
                const token = JSON.parse(accessTokenData).access;
                return token;
            } catch (error) {
                // Handle the error (e.g., log or throw a meaningful exception)
                console.error("Error parsing JSON:", error);
                return null; // or handle it in an appropriate way based on your application logic
            }
        },

        async updateAccessToken({ dispatch }) {
            try {
                const refreshToken = await api.getRefreshToken();
                if (refreshToken === 401 || refreshToken === 403) {
                    dispatch("removeAuthDataFromStorage");
                    throw new Error("Refresh token invalid or expired");
                }
                // Обновление токена в хранилище Vuex
                dispatch("updateToken", refreshToken);
                return refreshToken; // Возвращаем новый токен
            } catch (e) {
                dispatch("removeAuthDataFromStorage");
                console.error(e);
                throw e;
            }
        },

        // Добавьте действие для обновления токена в хранилище
        updateToken({ commit }, newToken) {
            commit("changeToken", newToken);
        },

        logOut({ dispatch, commit }) {
            dispatch("removeAuthDataFromStorage");
            commit("changeErrorStatus", "");
        },
        updateAuthorizationData({ commit },authorizationData) {
            commit("changeAuthorizationData", authorizationData);
        },
        updateLoginFieldFilledInStatus({ commit }, status) {
            commit("changeLoginFieldFilledInStatus", status);
        },
        updatePasswordFieldFilledInStatus({ commit }, status) {
            commit("changePasswordFieldFilledInStatus", status);
        },
        updateAuthorizationLoginButtonDisabledStatus({ commit }, status) {
            commit("changeAuthorizationLoginButtonDisabledStatus", status);
        },
    },

    mutations: {
        changeToken(state, newToken) {
            state.token = newToken
        },
        changeErrorStatus(state, error) {
            state.errorStatus = error;
        },
        changeAuthorizationData(state, authorizationData) {
            state.authorizationData = authorizationData;
        },
        changeLoginFieldFilledInStatus(state, status) {
            state.loginFieldFilledInStatus = status;
        },
        changePasswordFieldFilledInStatus(state, status) {
            state.passwordFieldFilledInStatus = status;
        },
        changeAuthorizationLoginButtonDisabledStatus(state, status) {
            state.authorizationLoginButtonDisabledStatus = status;
        },
    },

    getters: {
        getToken(state) {
            return state.token;
        },
        getErrorStatus(state) {
            return state.errorStatus;
        },
        getAuthorizationData(state) {
            return state.authorizationData;
        },
        getLoginFieldFilledInStatus(state) {
            return state.loginFieldFilledInStatus;
        },
        getPasswordFieldFilledInStatus(state) {
            return state.passwordFieldFilledInStatus;
        },
        getAuthorizationLoginButtonDisabledStatus(state) {
            return state.authorizationLoginButtonDisabledStatus;
        },
    }
}
