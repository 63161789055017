import { mapGetters } from "vuex";

export const widgetSortDateFromToFieldNotification = {
    computed: {
        ...mapGetters([
            "getLifetimeCasesInputSortDateValidStatus",
            "getLifetimeCasesInputSortDateFromFillValidStatus",
            "getLifetimeCasesInputSortDateToFillValidStatus"
        ]),
        getWidgetSortDateFromToFieldNotificationConditionInfluencePanel() {
            return (fieldName) => {
                switch (fieldName) {
                    case "dateFrom":
                        switch (true) {
                            case !this.getLifetimeCasesInputSortDateValidStatus && (this.getLifetimeCasesInputSortDateFromFillValidStatus && this.getLifetimeCasesInputSortDateToFillValidStatus):
                            case this.getLifetimeCasesInputSortDateValidStatus && (!this.getLifetimeCasesInputSortDateFromFillValidStatus && this.getLifetimeCasesInputSortDateToFillValidStatus):
                                return true;
                            default:
                                return false;
                        }
                    case "dateTo":
                        switch (true) {
                            case this.getLifetimeCasesInputSortDateValidStatus && (this.getLifetimeCasesInputSortDateFromFillValidStatus && !this.getLifetimeCasesInputSortDateToFillValidStatus):
                                return true;
                            case !this.getLifetimeCasesInputSortDateValidStatus && (this.getLifetimeCasesInputSortDateFromFillValidStatus && this.getLifetimeCasesInputSortDateToFillValidStatus):
                            case this.getLifetimeCasesInputSortDateValidStatus && (!this.getLifetimeCasesInputSortDateFromFillValidStatus && this.getLifetimeCasesInputSortDateToFillValidStatus):
                                return false;
                            default:
                                return "";
                        }
                    default:
                        return false;
                }
            }
        },
        getWidgetSortDateFromToFieldNotificationClassesInfluencePanel() {
            return (fieldName) => {
                switch (fieldName) {
                    case "dateFrom":
                        switch (true) {
                            case !this.getLifetimeCasesInputSortDateValidStatus && (this.getLifetimeCasesInputSortDateFromFillValidStatus && this.getLifetimeCasesInputSortDateToFillValidStatus):
                            case this.getLifetimeCasesInputSortDateValidStatus && (!this.getLifetimeCasesInputSortDateFromFillValidStatus && this.getLifetimeCasesInputSortDateToFillValidStatus):
                                return "field__invalid";
                            default:
                                return "";
                        }
                    case "dateTo":
                        switch (true) {
                            case this.getLifetimeCasesInputSortDateValidStatus && (this.getLifetimeCasesInputSortDateFromFillValidStatus && !this.getLifetimeCasesInputSortDateToFillValidStatus):
                                return "field__invalid";
                            default:
                                return "";
                        }
                    default:
                        return "";
                }
            }
        },
    }
}