import { api } from "@/api";


export default {
    state: {
        settingsReferenceOrganizationPage: 1,
        settingsReferenceOrganizationPageSize: 50,
        settingsReferenceOrganizations: {},
        settingsReferenceOrganizationsTotalPages: null,
        isSettingsReferenceOrganizationsLoading: false,
        isShowAddDepartmentForm: false,
    },
    actions: {
        async fetchSettingsReferenceOrganization({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceOrganizationsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const organizations = await api.getSettingsReferenceOrganization(queryParams, token);
                    commit("changeSettingsReferenceOrganizations", organizations.results);
                    commit("changeSettingsReferenceOrganizationsTotalPages", organizations.total_pages);
                    commit("updateSettingsReferenceOrganizationsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceOrganizationsLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async fetchMoreSettingsReferenceOrganizations({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceOrganizationsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const organizations = await api.getSettingsReferenceOrganization(queryParams, token);
                    commit("updateMoreSettingsReferenceOrganizations", organizations.results);
                    commit("updateSettingsReferenceOrganizationsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceOrganizationsLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSettingsReferenceOrganizationsLoader", false);
            }

        },
        async addSettingsReferenceOrganizations({ dispatch, commit }, [organization, type]) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const organizationData = await api.postSettingsReferenceOrganization(organization, token);
                    if (typeof organizationData === "object") {
                        commit("addNewSettingsReferenceOrganization", [organizationData, type]);
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return false;
                }
            } catch (error) {
                console.log(error);
                return false;
            }
        },
        async editSettingsReferenceOrganizations({ dispatch, commit }, organization ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const organizationData = await api.patchSettingsReferenceOrganization(organization, organization.id, token);
                    if (typeof organizationData === "object") {
                        commit("updateSettingsReferenceOrganizations", organizationData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        updateIsShowAddDepartmentForm({ commit }, status ) {
            commit("changeIsShowAddDepartmentForm", status);
        },
    },
    mutations: {
        changeSettingsReferenceOrganizations(state, settingsReferenceOrganizations) {
            state.settingsReferenceOrganizations = settingsReferenceOrganizations;
        },
        updateMoreSettingsReferenceOrganizations(state, settingsReferenceOrganizations) {
            state.settingsReferenceOrganizations = [...state.settingsReferenceOrganizations, ...settingsReferenceOrganizations];
        },
        changeSettingsReferenceOrganizationsTotalPages(state, settingsReferenceOrganizationsTotalPages) {
            state.settingsReferenceOrganizationsTotalPages = settingsReferenceOrganizationsTotalPages;
        },
        updateSettingsReferenceOrganizationsLoader(state, condition) {
            state.isSettingsReferenceOrganizationsLoading = condition;
        },
        addNewSettingsReferenceOrganization(state, [organization, type]) {
            if (type === "reference_organizations") {
                state.settingsReferenceOrganizations.unshift(organization);
            }
        },
        updateSettingsReferenceOrganizations(state, organization) {
            const index = state.settingsReferenceOrganizations.findIndex(item => item.id === organization.id);
            if (index !== -1) {
                const updatedOrganization = { ...organization }; // Создаем копию объекта organization
                const uniqueId = Object.values(updatedOrganization).join('_'); // Создаем уникальный идентификатор на основе значений объекта
                updatedOrganization.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
                state.settingsReferenceOrganizations.splice(index, 1, updatedOrganization); // Заменяем объект в массиве
            }
        },
        changeIsShowAddDepartmentForm(state, status) {
            state.isShowAddDepartmentForm = status;
        },
    },
    getters: {
        getSettingsReferenceOrganizations(state) {
            return state.settingsReferenceOrganizations;
        },
        getSettingsReferenceOrganizationsTotalPages(state) {
            return state.settingsReferenceOrganizationsTotalPages;
        },
        getSettingsReferenceOrganizationsLoaderStatus(state) {
            return state.isSettingsReferenceOrganizationsLoading;
        },
        getIsShowAddDepartmentForm(state) {
            return state.isShowAddDepartmentForm;
        },
    }
}