<template>
    <Dialog
        v-model:visible="checkDialogVisibility"
        modal
        header="Header"
        class="dialog"
    >
        <template #header>
            <div class="dialog__header">
                <span v-if="getLifetimeJournalDialogVisibility">Печать журналов регистрации прижизненных исследований (№
                    014-2/у)</span>
                <span v-if="getLifetimeProtocolDialogVisibility">Печать протоколов прижизненного исследования (№
                    014-1/у)</span>
                <span v-if="getLifetimeDirectionDialogVisibility">Печать направлений на прижизненное исследование (№
                    014/у)</span>
            </div>
        </template>
        <template #closeicon>
            <div style="display: none"></div>
        </template>
        <div class="dialog__body">
            Будет распечатано
            {{ getLifetimeProtocolDialogVisibility ? checkProtocolDocumentsCount() : getLifetimeCasesChecked.length || allLifetimeCases?.count || 1 }}
            документа(-ов). Действительно хотите распечатать?
        </div>
        <template #footer>
            <Button
                class="button__purple_border"
                label="Нет"
                @click="closeDialog()"
            />
            <Button class="button__purple" label="Да" @click="acceptPrint()" />
        </template>
    </Dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
export default {
    name: "AcceptPrintDialog",
    computed: {
        ...mapGetters([
            "getLifetimeJournalDialogVisibility",
            "getLifetimeProtocolDialogVisibility",
            "getLifetimeDirectionDialogVisibility",
            "allLifetimeCases",
            "getLifetimeCasesChecked",
        ]),
        checkDialogVisibility() {
            return (
                this.getLifetimeProtocolDialogVisibility ||
                this.getLifetimeDirectionDialogVisibility ||
                this.getLifetimeJournalDialogVisibility
            )
        },
        checkProtocolDocumentsCount() {
            return () => {
                switch (true) {
                    case this.getLifetimeCasesChecked.length !== 0:
                        return this.getLifetimeCasesChecked.filter((item) => item.statuses.current.status.toLowerCase() === "done").map((item) => item.id).length;
                    case this.getLifetimeCasesChecked.length === 0 && this.allLifetimeCases.length !== 0:
                        return this.allLifetimeCases.results.filter((item) => item.statuses.current.status.toLowerCase() === "done").map((item) => item.id).length;
                    default:
                        return 1;
                }
            }
        }
    },
    methods: {
        ...mapActions([
            "updateLifetimeProtocolDialogVisibility",
            "updateLifetimeDirectionDialogVisibility",
            "updateLifetimeJournalDialogVisibility",
            "sendPdfGenerationRequest",
            "connectToDocumentWS", // Добавляем connectToDocumentWS для подключения
        ]),
        closeDialog() {
            this.updateLifetimeProtocolDialogVisibility(false)
            this.updateLifetimeDirectionDialogVisibility(false)
            this.updateLifetimeJournalDialogVisibility(false)
        },
        async acceptPrint() {
            let selectedCases = []
            if (this.$route.name === "lifetimeCaseById") {
                selectedCases = [this.$route.params.lifetimeCaseId]
            } else {
                if (this.getLifetimeProtocolDialogVisibility) {
                    selectedCases = this.getLifetimeCasesChecked.length
                        ? this.getLifetimeCasesChecked.filter((item) => item.statuses.current.status.toLowerCase() === "done").map((item) => item.id)
                        : this.allLifetimeCases.results.filter((item) => item.statuses.current.status.toLowerCase() === "done").map((item) => item.id)
                } else {
                    selectedCases = this.getLifetimeCasesChecked.length
                        ? this.getLifetimeCasesChecked.map((item) => item.id)
                        : this.allLifetimeCases.results.map((item) => item.id)
                }
            }
            const documentType = this.getLifetimeProtocolDialogVisibility
                ? "PROTOCOL"
                : this.getLifetimeDirectionDialogVisibility
                ? "REFERRAL"
                : "JOURNAL"
            const printData = {
                request_id: Date.now(),
                action: "create_document",
                document_type: documentType,
                lifecase_ids: selectedCases,
            }

            let socket = this.$store.state.lifetimeCasesPrint.printWsConnection
            console.warn("this.$store.state", this.$store.state)
            if (!socket || socket.readyState !== WebSocket.OPEN) {
                console.warn(
                    "WebSocket is not open. Cannot send request. Attempting to reconnect..."
                )
                await this.connectToDocumentWS()
                socket = this.$store.state.printWsConnection
            } else {
                console.warn("Sending PDF generation request:", printData)
                this.sendPdfGenerationRequest(printData)
            }

            if (socket && socket.readyState === WebSocket.OPEN) {
                console.warn("Sending PDF generation request:", printData)
                this.sendPdfGenerationRequest(printData)
            } else {
                console.error(
                    "WebSocket is not open. Cannot send request. WebSocket state:",
                    socket ? socket.readyState : "No WebSocket connection"
                )
            }
            this.closeDialog()
        },
    },
}
</script>
