import { mapGetters } from "vuex";
export const fieldHints = {
    data() {
        return {
            fieldHintNotificationError: "Ошибка! Повторите запрос",
            fieldHintNotificationNotFound: "Данные не найдены",
            fieldHintNotificationCheckData: "Сверьте заполненную информацию о пациенте",
            fieldHintNotificationPatientDirection: "За (последние 24 часа) для пациента с данным СНИЛС было заведено направлений: (количество)",
            fieldHintWarningPatientIpa: "Введите не менее 11 символов",
            fieldHintWarningPatientInsurance: "Введите не менее 16 символов",
            fieldHintNotificationErrorSortDateFrom: "Неверный диапазон",
            fieldHintNotificationSortDatesRequiredFillIn: "Обязательно для заполнения",
            fieldHintNotificationSortDatesFillIn: "Заполните поле",
            fieldHintNotificationDataFillIn: "Выберите из списка",
            fieldHintNotificationDataNotFound: "Не найдено",
            fieldHintNotificationFillField: "Необходимо заполнить поле",
        }
    },
    computed: {
        ...mapGetters([
            "getLifetimeCasesInputSortDateValidStatus",
            "getLifetimeCasesInputSortDateFromFillValidStatus",
            "getLifetimeCasesInputSortDateToFillValidStatus"
        ]),
        getFieldHintPatientMessage() {
            switch (true) {
                case this.getIsLifetimeCasesPatientDataIpaSearchNonFound:
                    return this.fieldHintNotificationNotFound;
                case this.getIsLifetimeCasesPatientDataIpaSearchSuccess:
                    return this.fieldHintNotificationCheckData;
                case this.getIsLifetimeCasesPatientDataIpaSearchError:
                    return this.fieldHintNotificationError;
                default:
                    return "Введите СНИЛС для поиска информации в базе данных";
            }
        },
        getPatientHintIcon() {
            return() => {
                switch (true) {
                    case this.getIsLifetimeCasesPatientDataIpaSearchSuccess:
                        return "icon-success";
                    case this.getIsLifetimeCasesPatientDataIpaSearchNonFound:
                        return "icon-error";
                    case this.getIsLifetimeCasesPatientDataIpaSearchError:
                        return "icon-error";
                    default:
                        return "icon-info";
                }
            }
        },
        getFieldHintIcdCodeMessage() {
            switch (true) {
                case this.getIcdCodeReferenceListShowStatus:
                    return this.fieldHintNotificationDataFillIn;
                case !this.getIcdCodeReferenceListShowStatus:
                    return this.fieldHintNotificationDataNotFound;
                default:
                    return "";
            }
        },
        getFieldHintLocalizationMessage() {
            switch (true) {
                case this.getLocalizationReferenceListShowStatus:
                    return this.fieldHintNotificationDataFillIn;
                case !this.getLocalizationReferenceListShowStatus:
                    return this.fieldHintNotificationDataNotFound;
                default:
                    return "";
            }
        },
        getFieldHintBiopsyPositionMessage() {
            switch (true) {
                case this.getBiopsyPositionReferenceListShowStatus:
                    return this.fieldHintNotificationDataFillIn;
                case !this.getBiopsyPositionReferenceListShowStatus:
                    return this.fieldHintNotificationDataNotFound;
                default:
                    return "";
            }
        },
        getFieldHintResponsibleStaffMemberMessage() {
            switch (true) {
                case this.getResponsibleStaffMemberReferenceListShowStatus:
                    return this.fieldHintNotificationDataFillIn;
                case !this.getResponsibleStaffMemberReferenceListShowStatus:
                    return this.fieldHintNotificationDataNotFound;
                default:
                    return "";
            }
        },
        getFieldHintOrganizationMessage() {
            switch (true) {
                case this.getOrganizationReferenceListShowStatus:
                    return this.fieldHintNotificationDataFillIn;
                case !this.getOrganizationReferenceListShowStatus:
                    return this.fieldHintNotificationDataNotFound;
                default:
                    return "";
            }
        },
        getFieldHintPreviousOrganizationMessage() {
            switch (true) {
                case this.getPreviousOrganizationReferenceListShowStatus:
                    return this.fieldHintNotificationDataFillIn;
                case !this.getPreviousOrganizationReferenceListShowStatus:
                    return this.fieldHintNotificationDataNotFound;
                default:
                    return "";
            }
        },
        getFieldHintSettingsReferenceDepartmentOrganizationMessage() {
            switch (true) {
                case this.getSettingsReferenceDepartmentOrganizationListShowStatus:
                    return this.fieldHintNotificationDataFillIn;
                case !this.getSettingsReferenceDepartmentOrganizationListShowStatus:
                    return this.fieldHintNotificationDataNotFound;
                default:
                    return "";
            }
        },
        getFieldHintSettingsReferenceDoctorOrganizationMessage() {
            switch (true) {
                case this.getSettingsReferenceDoctorOrganizationListShowStatus:
                    return this.fieldHintNotificationDataFillIn;
                case !this.getSettingsReferenceDoctorOrganizationListShowStatus:
                    return this.fieldHintNotificationDataNotFound;
                default:
                    return "";
            }
        },
        getFieldHintSettingsReferenceDoctorDepartmentMessage() {
            switch (true) {
                case this.getSettingsReferenceDoctorDepartmentListShowStatus:
                    return this.fieldHintNotificationDataFillIn;
                case !this.getSettingsReferenceDoctorDepartmentListShowStatus:
                    return this.fieldHintNotificationDataNotFound;
                default:
                    return "";
            }
        },
        getFieldHintOrganizationDepartmentMessage() {
            switch (true) {
                case this.getOrganizationDepartmentReferenceListShowStatus:
                    return this.fieldHintNotificationDataFillIn;
                case !this.getOrganizationDepartmentReferenceListShowStatus:
                    return this.fieldHintNotificationDataNotFound;
                default:
                    return "";
            }
        },
        getFieldHintOrganizationDepartmentDoctorMessage() {
            switch (true) {
                case this.getOrganizationDepartmentDoctorReferenceListShowStatus:
                    return this.fieldHintNotificationDataFillIn;
                case !this.getOrganizationDepartmentDoctorReferenceListShowStatus:
                    return this.fieldHintNotificationDataNotFound;
                default:
                    return "";
            }
        },
        getFieldHintJournalSortDatesMessage() {
            switch (true) {
                case !this.getLifetimeCasesInputSortDateValidStatus && (this.getLifetimeCasesInputSortDateFromFillValidStatus && this.getLifetimeCasesInputSortDateToFillValidStatus):
                    return this.fieldHintNotificationErrorSortDateFrom;
                case this.getLifetimeCasesInputSortDateValidStatus && (!this.getLifetimeCasesInputSortDateFromFillValidStatus && this.getLifetimeCasesInputSortDateToFillValidStatus):
                case this.getLifetimeCasesInputSortDateValidStatus && (this.getLifetimeCasesInputSortDateFromFillValidStatus && !this.getLifetimeCasesInputSortDateToFillValidStatus):
                    return this.fieldHintNotificationSortDatesFillIn;
                default:
                    return "";
            }
        },
        getFieldHintAuthorizationMessage() {
            switch (true) {
                case this.getLoginFieldFilledInStatus:
                    return this.fieldHintNotificationFillField;
                case this.getPasswordFieldFilledInStatus:
                    return this.fieldHintNotificationFillField;
                default:
                    return "";
            }
        },
    }
}