import { mapActions, mapGetters } from "vuex";
export const loadScrollContent = {
    // data() {
    //     return {
    //         currentScrollTop: 0
    //     }
    // },
    computed: {
        ...mapGetters(["getScrollArrowTopButtonStatus"]),
        getComparisonUploadContentHeights() {
            return (event) => {
                const target = event.target;
                return target.scrollHeight - Math.ceil(target.scrollTop) <= target.clientHeight;
            }
        },
    },
    methods: {
        ...mapActions(["updateScrollArrowTopButtonStatus"]),
        setScrollArrowTopButtonStatus(event) {
            if (event.target.scrollTop === 0) {
                this.updateScrollArrowTopButtonStatus(false);
                return;
            }
            if (this.getScrollArrowTopButtonStatus) {
                return;
            }
            this.updateScrollArrowTopButtonStatus(true);
        },
        async getNextDataListAccordingScrollPosition(event, callback, setScrollArrowTopButtonStatus) {
            // if (event.target.scrollTop === this.currentScrollTop) {//если текущий scrollTop === предыдущему, значит сработал горизонтальный скролл
            //     return;
            // }
            // this.currentScrollTop = event.target.scrollTop; //сохраняем текущий scrollTop
            if (setScrollArrowTopButtonStatus) {
                this.setScrollArrowTopButtonStatus(event);
            }
            const comparisonUploadContentHeights = this.getComparisonUploadContentHeights(event);
            if (comparisonUploadContentHeights) {
                await callback();
            }
        }
    }
};