import { lifetimeApi } from "@/api";

export default {
    state: {
        lifetimeReceptionReferralDefects: null,
        lifetimeReceptionMaterialDefects: null,
        lifetimeCasesDefectForm: null,
        editLifetimeDefectStatus: false,
        lifetimeFullDefectReferral: false,
        lifetimeFullDefectMaterial: false
    },
    actions: {
        async receiveLifetimeCaseDefect({ dispatch, state }, status) {
            try {
                const token = await dispatch("fetchAccessToken");
                switch (status) {
                    case "addReferral":
                        dispatch("fetchLifetimeAddReferralDefect", [token, state.lifetimeCasesDefectForm]);
                        return;
                    case "editReferral":
                        dispatch("fetchLifetimeEditReferralDefect", [token, state.lifetimeCasesDefectForm]);
                        return;
                    case "deleteReferral":
                        dispatch("fetchLifetimeDeleteReferralDefect", token);
                        return;
                    case "addMaterial":
                        dispatch("fetchLifetimeAddMaterialDefect", [token, state.lifetimeCasesDefectForm]);
                        return;
                    case "editMaterial":
                        dispatch("fetchLifetimeEditMaterialDefect", [token, state.lifetimeCasesDefectForm]);
                        return;
                    case "deleteMaterial":
                        dispatch("fetchLifetimeDeleteMaterialDefect", token);
                        return;
                    default:
                        // dispatch("addLifetimeReferralDefect", lifetimeReferralDefect);
                        return;
                }
                // dispatch("fetchLifetimeReferralDefect", [token, state.lifetimeCasesDefectForm, status]);
            } catch (e) {
                console.error(e);
            }
        },
        async fetchLifetimeAddReferralDefect({ commit, dispatch, rootGetters }, [token, lifetimeCasesDefectData]) {
            try {
                const referralDefect = await lifetimeApi.putLifetimeCaseReferralDefect(rootGetters.returnLifetimeCaseById.id, lifetimeCasesDefectData, token);
                commit("changeLifetimeReceptionReferralDefect", referralDefect);
                dispatch("checkLifetimeCaseFullDefect", referralDefect);
            } catch (e) {
                console.error(e);
            }
        },
        async fetchLifetimeEditReferralDefect({ commit, dispatch, rootGetters, state }, [token, lifetimeCasesDefectData]) {
            try {
                const referralDefect = await lifetimeApi.putLifetimeCaseReferralDefect(rootGetters.returnLifetimeCaseById.id, lifetimeCasesDefectData, token);
                commit("changeLifetimeReceptionReferralDefect", referralDefect);
                if(state.lifetimeFullDefectReferral) {
                    commit("changeLifetimeFullDefectReferral", false);
                    dispatch("returnLifetimeCaseToReceptionStatus");
                    return;
                }
                dispatch("checkLifetimeCaseFullDefect", referralDefect);
            } catch (e) {
                console.error(e);
            }
        },
        async fetchLifetimeDeleteReferralDefect({ commit, dispatch, rootGetters, state }, token) {
            try {
                await lifetimeApi.deleteLifetimeCaseReferralDefect(rootGetters.returnLifetimeCaseById.id, token);
                // TODO Добавить проверку, что удаление прошло успешно
                commit("changeLifetimeReceptionReferralDefect", null);
                if(state.lifetimeFullDefectReferral) {
                    commit("changeLifetimeFullDefectReferral", false);
                    await dispatch("reloadLifetimeCaseById"); //TODO перезапрос для обновления данных в случае
                    // const statusData = {
                    //     assigned_first_employee_id: rootGetters.returnLifetimeCaseById.statuses.current.assigned_first_employee.id,
                    //     assigned_second_employee_id: null
                    // }
                    // dispatch("setLifetimeCaseStatusToWorkInProgress", statusData);
                }
            } catch (e) {
                console.error(e);
            }
        },
        async fetchLifetimeAddMaterialDefect({ commit, dispatch, rootGetters }, [token, lifetimeCasesDefectData]) {
            try {
                const materialDefect = await lifetimeApi.putLifetimeCaseMaterialDefect(rootGetters.returnLifetimeCaseById.id, lifetimeCasesDefectData, token);
                await commit("changeLifetimeReceptionMaterialDefect", materialDefect);
                dispatch("checkLifetimeCaseFullDefect", materialDefect);
                dispatch("getLifetimeCaseById", rootGetters.returnLifetimeCaseById.id);
            } catch (e) {
                console.error(e);
            }
        },
        async fetchLifetimeEditMaterialDefect({ commit, dispatch, rootGetters, state }, [token, lifetimeCasesDefectData]) {
            try {
                const materialDefect = await lifetimeApi.putLifetimeCaseMaterialDefect(rootGetters.returnLifetimeCaseById.id, lifetimeCasesDefectData, token);
                commit("changeLifetimeReceptionMaterialDefect", materialDefect);
                if(state.lifetimeFullDefectMaterial) {
                    commit("changeLifetimeFullDefectMaterial", false);
                    dispatch("returnLifetimeCaseToReceptionStatus");
                    return;
                }
                dispatch("getLifetimeCaseById", rootGetters.returnLifetimeCaseById.id);
            } catch (e) {
                console.error(e);
            }
        },
        async fetchLifetimeDeleteMaterialDefect({ commit, dispatch, rootGetters, state }, token) {
            try {
                await lifetimeApi.deleteLifetimeCaseMaterialDefect(rootGetters.returnLifetimeCaseById.id, token);
                // TODO Добавить проверку, что удаление прошло успешно
                commit("changeLifetimeReceptionMaterialDefect", null);
                if(state.lifetimeFullDefectMaterial) {
                    commit("changeLifetimeFullDefectMaterial", false);
                    // dispatch("returnLifetimeCaseToReceptionStatus");
                }
                dispatch("getLifetimeCaseById", rootGetters.returnLifetimeCaseById.id);
            } catch (e) {
                console.error(e);
            }
        },
        // updateLifetimeReceptionDefect({ commit }, lifetimeReceptionDefect) {
        //     commit("changeLifetimeReceptionDefect", lifetimeReceptionDefect);
        // },
        updateLifetimeCasesDefectForm({ commit }, defectData) {
            commit("changeLifetimeCasesDefectForm", defectData);
        },
        updateEditLifetimeDefectStatus({ commit }, status) {
            commit("changeEditLifetimeDefectStatus", status);
        },
        async initLifetimeCaseDefect({ commit, dispatch }, lifetimeCaseDefect) {
            await dispatch("fetchDefectCategories");
            await dispatch("fetchDefectCategoriesPartial");
            if (lifetimeCaseDefect.referral) {
                commit("changeLifetimeReceptionReferralDefect", lifetimeCaseDefect.referral);
                dispatch("checkLifetimeCaseFullDefect", lifetimeCaseDefect.referral);
            }
            if (!lifetimeCaseDefect.referral) {
                commit("changeLifetimeReceptionReferralDefect", null);
                commit("changeLifetimeFullDefectReferral", false);
            }
            if (lifetimeCaseDefect.material) {
                commit("changeLifetimeReceptionMaterialDefect", lifetimeCaseDefect.material);
                dispatch("checkLifetimeCaseFullDefect", lifetimeCaseDefect.material);
            }
            if (!lifetimeCaseDefect.material) {
                commit("changeLifetimeReceptionMaterialDefect", null);
                commit("changeLifetimeFullDefectMaterial", false);
            }
        },
        async checkLifetimeCaseFullDefect({ commit, dispatch, rootGetters }, lifetimeCaseDefect) {
            const categories = rootGetters.getDefectCategoriesTransformedById[lifetimeCaseDefect?.view_of_defect];
            switch (true) {
                case categories?.type_of_defect === "FULL" && categories?.defect_object === "REFERRAL":
                    commit("changeLifetimeFullDefectReferral", true);
                    dispatch("setLifetimeCaseStatus", "DEFECT");
                    dispatch("updateLifetimeWorkInProgressStatus", false);
                    return;
                case categories?.type_of_defect === "FULL" && categories?.defect_object === "MATERIAL":
                    commit("changeLifetimeFullDefectMaterial", true);
                    dispatch("setLifetimeCaseStatus", "DEFECT");
                    dispatch("updateLifetimeWorkInProgressStatus", false);
                    return;
                case categories?.type_of_defect === "PARTIAL" && categories?.defect_object === "REFERRAL":
                    commit("changeLifetimeFullDefectReferral", false);
                    // dispatch("returnLifetimeCaseToReceptionStatus");
                    return;
                case categories?.type_of_defect === "PARTIAL" && categories?.defect_object === "MATERIAL":
                    commit("changeLifetimeFullDefectMaterial", false);
                    // dispatch("returnLifetimeCaseToReceptionStatus");
                    return;
                default:
                    return;
            }
        }
    },
    mutations: {
        changeLifetimeReceptionReferralDefect(state, lifetimeReceptionReferralDefect) {
            state.lifetimeReceptionReferralDefects = lifetimeReceptionReferralDefect;
        },
        changeLifetimeReceptionMaterialDefect(state, lifetimeReceptionMaterialDefect) {
            state.lifetimeReceptionMaterialDefects = lifetimeReceptionMaterialDefect;
        },
        changeLifetimeCasesDefectForm(state, defectData) {
            state.lifetimeCasesDefectForm = defectData;
        },
        changeEditLifetimeDefectStatus(state, status) {
            state.editLifetimeDefectStatus = status;
        },
        changeLifetimeFullDefectReferral(state, lifetimeFullDefectReferral) {
            state.lifetimeFullDefectReferral = lifetimeFullDefectReferral;
        },
        changeLifetimeFullDefectMaterial(state, lifetimeFullDefectMaterial) {
            state.lifetimeFullDefectMaterial = lifetimeFullDefectMaterial;
        },
    },
    getters: {
        getLifetimeReceptionReferralDefect(state) {
            return state.lifetimeReceptionReferralDefects;
        },
        getLifetimeReceptionMaterialDefect(state) {
            return state.lifetimeReceptionMaterialDefects;
        },
        getLifetimeCasesDefectForm(state) {
            return state.lifetimeCasesDefectForm;
        },
        getEditLifetimeDefectStatus(state) {
            return state.editLifetimeDefectStatus;
        },
        getLifetimeFullDefectReferral(state) {
            return state.lifetimeFullDefectReferral;
        },
        getLifetimeFullDefectMaterial(state) {
            return state.lifetimeFullDefectMaterial;
        },
    },
}