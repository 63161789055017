import { api } from "@/api";

export default {
    state: {
        settingsPermissions: [],
    },
    actions: {
        async fetchSettingsPermissions({ dispatch, commit/*, state*/ }, queryParams = {}) {
            try {
                const token = await dispatch('getAccessToken');
                if (token) {
                    const permissions = await api.getSettingsReferencePermissions(queryParams, token);
                    commit('changeSettingsPermissions', permissions);

                } else {
                    dispatch('getRedirectAuthAfterInvalidRefreshTokenAction');
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async addSettingsPermissionsGroup({ dispatch }, groupData) {
            try {
                const token = await dispatch('getAccessToken');
                if (token) {
                    await api.postSettingsReferencePermissionsGroup(groupData, token);
                    await dispatch('fetchSettingsPermissions');

                } else {
                    dispatch('getRedirectAuthAfterInvalidRefreshTokenAction');
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async editSettingsPermissionsGroup({ dispatch }, [groupId, groupData]) {
            try {
                const token = await dispatch('getAccessToken');
                if (token) {
                    await api.patchSettingsReferencePermissionsGroup(groupId, groupData, token);
                    await dispatch('fetchSettingsPermissions');

                } else {
                    dispatch('getRedirectAuthAfterInvalidRefreshTokenAction');
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async uncheckSettingsPermissionsGroup({ dispatch }, [groupId, permissionId]) {
            try {
                const token = await dispatch('getAccessToken');
                if (token) {
                    await api.deleteSettingsReferencePermissionsGroupUncheck(groupId, permissionId, token);
                    await dispatch('fetchSettingsPermissions');

                } else {
                    dispatch('getRedirectAuthAfterInvalidRefreshTokenAction');
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async deleteSettingsPermissionsGroup({ dispatch }, groupId) {
            try {
                const token = await dispatch('getAccessToken');
                if (token) {
                    await api.deleteSettingsReferencePermissionsGroup(groupId, token);
                    await dispatch('fetchSettingsPermissions');

                } else {
                    dispatch('getRedirectAuthAfterInvalidRefreshTokenAction');
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    mutations: {
        changeSettingsPermissions(state, settingsPermissions) {
            state.settingsPermissions = settingsPermissions;
        },
    },
    getters: {
        getSettingsPermissions(state) {
            return state.settingsPermissions;
        },
    },
}
