import { lifetimeApi } from "@/api";
import moment from "moment";

export default {
    state: {
        lifetimeCaseStatus: "",
        lifetimeTransferData: null,
        lifetimeWorkInProgressStatus: false
    },
    actions: {
        async updateLifetimeCaseStatus({ dispatch }, statusData) {
            const token = await dispatch("fetchAccessToken");
            dispatch("fetchLifetimeCaseStatus", [token, statusData]);
        },
        async setLifetimeCaseStatusToWorkInProgress({ dispatch, rootGetters }, statusData = rootGetters.getLifetimeTransferData) {
            const token = await dispatch("fetchAccessToken");
            try {
                await lifetimeApi.patchLifetimeCaseStatusSetWorkInProgress(rootGetters.returnLifetimeCaseById.id, statusData, token);
                await dispatch("reloadLifetimeCaseById"); //TODO перезапрос для обновления данных в случае
            } catch (e) {
                console.error(e);
            }
        },
        async unsetLifetimeCaseStatusToWorkInProgress({ dispatch, rootGetters }) {
            const token = await dispatch("fetchAccessToken");
            try {
                await lifetimeApi.patchLifetimeCaseStatusUnsetWorkInProgress(rootGetters.returnLifetimeCaseById.id, token);
                // await dispatch("reloadLifetimeCaseById"); //TODO перезапрос для обновления данных в случае
            } catch (e) {
                console.error(e);
            }
        },
        async fetchLifetimeCaseStatusByAction({ dispatch, rootGetters, state }, action) {
            const token = await dispatch("fetchAccessToken");
            let response = null;
            try {
                switch (action) {
                    case "updateRegister":
                        await lifetimeApi.patchLifetimeCaseStatusUpdateRegister(rootGetters.returnLifetimeCaseById.id, rootGetters.getLifetimeRegisterData, token);
                        await dispatch("reloadLifetimeCaseById"); //TODO перезапрос для обновления данных в случае
                        return;
                    case "setReception":
                        if (!rootGetters.getLifetimeFieldsFillTransferToReceptionStatus) {// пока что в lifetimeFieldsFillTransferToReceptionStatus временно передается true
                            return;
                        }
                        response = await lifetimeApi.postLifetimeCaseStatusSetReception(rootGetters.returnLifetimeCaseById.id, state.lifetimeTransferData, token);
                        await dispatch("reloadLifetimeCaseById"); //TODO перезапрос для обновления данных в случае
                        return response.ok;
                    case "updateReception":
                        await lifetimeApi.patchLifetimeCaseStatusUpdateReception(rootGetters.returnLifetimeCaseById.id, state.lifetimeTransferData, token);
                        await dispatch("reloadLifetimeCaseById"); //TODO перезапрос для обновления данных в случае
                        return;
                    case "setMacroscopy":
                        response = await lifetimeApi.postLifetimeCaseStatusSetMacroscopy(rootGetters.returnLifetimeCaseById.id, state.lifetimeTransferData, token);
                        await dispatch("reloadLifetimeCaseById"); //TODO перезапрос для обновления данных в случае
                        return response.ok;
                    case "updateMacroscopy":
                        await lifetimeApi.patchLifetimeCaseStatusUpdateMacroscopy(rootGetters.returnLifetimeCaseById.id, state.lifetimeTransferData, token);
                        await dispatch("reloadLifetimeCaseById"); //TODO перезапрос для обновления данных в случае
                        return;
                    case "setSamplePreparation":
                        response = await lifetimeApi.postLifetimeCaseStatusSetSamplePreparation(rootGetters.returnLifetimeCaseById.id, state.lifetimeTransferData, token);
                        await dispatch("reloadLifetimeCaseById"); //TODO перезапрос для обновления данных в случае
                        return response.ok;
                    case "updateSamplePreparation":
                        await lifetimeApi.patchLifetimeCaseStatusUpdateSamplePreparation(rootGetters.returnLifetimeCaseById.id, state.lifetimeTransferData, token);
                        await dispatch("reloadLifetimeCaseById"); //TODO перезапрос для обновления данных в случае
                        return;
                    case "updateMicroscopy":
                        await lifetimeApi.patchLifetimeCaseStatusUpdateMicroscopy(rootGetters.returnLifetimeCaseById.id, state.lifetimeTransferData, token);
                        await dispatch("reloadLifetimeCaseById"); //TODO перезапрос для обновления данных в случае
                        return;
                    case "setDone":
                        response = await lifetimeApi.postLifetimeCaseStatusSetDone(
                            rootGetters.returnLifetimeCaseById.id,
                            { assigned_first_employee_position: rootGetters.returnLifetimeCaseById.statuses.microscopy.assigned_first_employee_position.id },
                            token
                        );
                        await dispatch("reloadLifetimeCaseById"); //TODO перезапрос для обновления данных в случае
                        return response.ok;
                    default:
                        await lifetimeApi.patchLifetimeCaseStatus(rootGetters.returnLifetimeCaseById.id, state.lifetimeTransferData, token);
                        await dispatch("reloadLifetimeCaseById"); //TODO перезапрос для обновления данных в случае
                        return;
                }
            } catch (e) {
                console.error(e);
            }
        },
        async fetchLifetimeCaseStatus({ dispatch, rootGetters }, [token, statusData = rootGetters.getLifetimeTransferData]) {
            try {
                await lifetimeApi.patchLifetimeCaseStatus(rootGetters.returnLifetimeCaseById.id, statusData, token);
                await dispatch("reloadLifetimeCaseById"); //TODO перезапрос для обновления данных в случае
            } catch (e) {
                console.error(e);
            }
        },
        reloadLifetimeCaseById({ dispatch, rootGetters }) {
            dispatch("updateLifetimeCaseCurrentStatusDataById");
            dispatch("getLifetimeCaseById", rootGetters.returnLifetimeCaseById.id);
        },
        updateLifetimeCaseCurrentStatusDataById({ commit, dispatch, rootGetters }) {
            dispatch("checkRequiredFieldsForTransferToReceptionStatus");
            commit("changeLifetimeCaseStatus", rootGetters.returnLifetimeCaseById?.statuses?.current?.status);
            commit("changeLifetimeWorkInProgressStatus", rootGetters.returnLifetimeCaseById?.statuses?.current?.is_work_in_progress);
        },
        setLifetimeCaseStatus({ commit }, status) {
            commit("changeLifetimeCaseStatus", status);
        },
        updateLifetimeTransferData({ commit }, lifetimeTransferData) {
            commit("changeLifetimeTransferData", lifetimeTransferData);
        },
        updateLifetimeWorkInProgressStatus({ commit }, status) {
            commit("changeLifetimeWorkInProgressStatus", status);
        },
        async returnLifetimeCaseToReceptionStatus({ commit, dispatch, rootGetters, state }) {
            const statusData = {
                status: "RECEPTION",
                set_timestamp: moment().toISOString(),
                assigned_first_employee_id: rootGetters.returnLifetimeCaseById.statuses.current.assigned_first_employee.id,
                assigned_second_employee_id: null,
                comment: rootGetters.returnLifetimeCaseById.statuses.current.comment
            };
            commit("changeLifetimeTransferData", statusData);
            await dispatch("updateLifetimeCaseStatus", state.lifetimeTransferData);
            dispatch("setLifetimeCaseStatusToWorkInProgress");
        }
    },
    mutations: {
        changeLifetimeCaseStatus(state, status) {
            state.lifetimeCaseStatus = status;
        },
        changeLifetimeTransferData(state, lifetimeTransferData) {
            state.lifetimeTransferData = lifetimeTransferData;
        },
        changeLifetimeWorkInProgressStatus(state, status) {
            state.lifetimeWorkInProgressStatus = status;
        },
    },
    getters: {
        getLifetimeCaseStatus(state) {
            return state.lifetimeCaseStatus;
        },
        getLifetimeTransferData(state) {
            return state.lifetimeTransferData;
        },
        getLifetimeWorkInProgressStatus(state) {
            return state.lifetimeWorkInProgressStatus;
        },
    }
}