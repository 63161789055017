export const referenceColoringFields = {
    data() {
        return {
            referenceColoringTableDescriptionData: [
                { field: "active", header: "", columnWidth: "32px"},
                { field: "title", header: "Наименование методики, реакции, определения", columnWidth: "350px"},
                { field: "coloring_method", header: "Метод окраски", columnWidth: "350px"},
                { field: "code_nsi", header: "Код НСИ", columnWidth: "70px"},
                { field: "title_nsi", header: "Наименование в НСИ", columnWidth: "auto"},
            ],
        }
    },
}