export const lifetimePatientFields = {
    data() {
        return {
            lifetimePatientDescriptionFields: [
                {
                    patient_surname: ["Фамилия:", "checkingStringData"],
                    patient_name: ["Имя:", "checkingStringData"],
                    patient_patronymic: ["Отчество:", "checkingStringData"],
                    patient_gender: ["Пол:", "checkingStringData"],
                    patient_birthday: ["Дата рождения:", "changeTimezoneDateFormat"],
                    patientAge: ["Возраст:", "calcAge"],
                },
                {
                    patient_insurance: ["Полис ОМС:", "checkingStringData"],
                    patient_ipa: ["СНИЛС:", "checkingStringData"],
                    patient_phone: ["Номер телефона:", "checkingStringData"],
                    patient_address_area: ["Местность:", "checkingStringData"],
                    patientAddress: ["Адрес:", "checkingStringData"],
                }
            ],
        }
    }
}