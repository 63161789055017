import { api } from "@/api";

export default {
    state: {
        settingsReferenceColoringMethodPage: 1,
        settingsReferenceColoringMethodPageSize: 50,
        settingsReferenceColoringMethods: {},
        settingsReferenceColoringMethodsTotalPages: null,
        isSettingsReferenceColoringMethodsLoading: false,
    },
    actions: {
        async fetchSettingsReferenceColoringMethod({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceColoringMethodsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const coloringMethods = await api.getSettingsReferenceColoringMethod(queryParams, token);
                    commit("changeSettingsReferenceColoringMethods", coloringMethods.results);
                    commit("changeSettingsReferenceColoringMethodsTotalPages", coloringMethods.total_pages);
                    commit("updateSettingsReferenceColoringMethodsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceColoringMethodsLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async fetchMoreSettingsReferenceColoringMethods({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceColoringMethodsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const coloringMethods = await api.getSettingsReferenceColoringMethod(queryParams, token);
                    commit("updateMoreSettingsReferenceColoringMethods", coloringMethods.results);
                    commit("updateSettingsReferenceColoringMethodsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceColoringMethodsLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSettingsReferenceColoringMethodsLoader", false);
            }

        },
        async addSettingsReferenceColoringMethods({ dispatch, commit }, coloringMethod ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const coloringMethodData = await api.postSettingsReferenceColoringMethod(coloringMethod, token);
                    if (typeof coloringMethodData === "object") {
                        commit("addNewSettingsReferenceColoringMethods", coloringMethodData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async editSettingsReferenceColoringMethods({ dispatch, commit }, coloringMethod ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const coloringMethodData = await api.patchSettingsReferenceColoringMethod(coloringMethod, token);
                    if (typeof coloringMethodData === "object") {
                        commit("updateSettingsReferenceColoringMethods", coloringMethodData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    mutations: {
        changeSettingsReferenceColoringMethods(state, settingsReferenceColoringMethods) {
            state.settingsReferenceColoringMethods = settingsReferenceColoringMethods;
        },
        updateMoreSettingsReferenceColoringMethods(state, settingsReferenceColoringMethods) {
            state.settingsReferenceColoringMethods = [...state.settingsReferenceColoringMethods, ...settingsReferenceColoringMethods];
        },
        changeSettingsReferenceColoringMethodsTotalPages(state, settingsReferenceColoringMethodsTotalPages) {
            state.settingsReferenceColoringMethodsTotalPages = settingsReferenceColoringMethodsTotalPages;
        },
        updateSettingsReferenceColoringMethodsLoader(state, condition) {
            state.isSettingsReferenceColoringMethodsLoading = condition;
        },
        updateSettingsReferenceColoringMethods(state, biobsyType) {
            const index = state.settingsReferenceColoringMethods.findIndex(item => item.id === biobsyType.id);
            if (index !== -1) {
                const updatedColoringMethod = { ...biobsyType }; // Создаем копию объекта biobsyType
                const uniqueId = Object.values(updatedColoringMethod).join('_'); // Создаем уникальный идентификатор на основе значений объекта
                updatedColoringMethod.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
                state.settingsReferenceColoringMethods.splice(index, 1, updatedColoringMethod); // Заменяем объект в массиве
            }
        },
        addNewSettingsReferenceColoringMethods(state, biobsyType) {
            const updatedColoringMethod = { ...biobsyType }; // Создаем копию объекта biobsyType
            const uniqueId = Object.values(updatedColoringMethod).join('_'); // Создаем уникальный идентификатор на основе значений объекта
            updatedColoringMethod.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
            state.settingsReferenceColoringMethods.unshift(updatedColoringMethod);
        },
    },
    getters: {
        getSettingsReferenceColoringMethods(state) {
            return state.settingsReferenceColoringMethods;
        },
        getSettingsReferenceColoringMethodsTotalPages(state) {
            return state.settingsReferenceColoringMethodsTotalPages;
        },
        getSettingsReferenceColoringMethodsLoaderStatus(state) {
            return state.isSettingsReferenceColoringMethodsLoading;
        },
    }
}