<template>
    <div class="main__left_scroll">
        <div class="sidebar__container">
            <div class="sidebar__container_up">
                <div class="header__left">
                    <div class="header__left_logo" @click.stop="updateActiveMenuShowStatus('lifetimeCases', 'submenu')">
                        <router-link :to="lifetimeCasesLink">
                            <img src="../assets/img/logo-morfis-medium.svg" alt="logo" />
                        </router-link>
                    </div>
                    <div class="mob_logo" @click.stop="updateActiveMenuShowStatus('lifetimeCases', 'submenu')">
                        <router-link :to="lifetimeCasesLink">
                            <img src="../assets/img/logo_min.png" alt="logo" />
                        </router-link>
                    </div>
                    <i 
                        class="chevron__left"
                        :class="{ rotate: getInterfaceMenuShowBtn }"
                        @click.stop="updateMenuShowBtnStatus"
                        v-html="getIconTemplate('icon-sidebar-arrow-left')"
                    ></i>
                </div>
                <nav class="sidebar">
                    <ul 
                        class="sidebar__menu"
                        :class="{ 'sidebar__menu-hidden': getInterfaceMenuShowBtn }"
                    >
                        <li
                            v-for="(menuItem, key, index) in sidebarMenu"
                            :key="`menuItem-${key}${index}`"
                            :class="getConditionForSidebarMenuItemActiveClass(key)"
                            style="position: relative; overflow: visible;"
                            @click.stop="!getCurrentItemSubmenu(key) ? updateActiveMenuShowStatus(key, 'submenu') : ''"
                        >
                            <div v-if="!getCurrentItemSubmenu(key)" class="sidebar__menu_list">
                                <router-link :to="{ name: `${key}` }" class="sidebar__menu_link">
                                    <i
                                        class="sidebar__menu_icon--flex"
                                        v-html="getIconTemplate(menuItem[1])" 
                                    ></i>
                                    <span class="sidebar__menu_link--text">{{ menuItem[0] }}</span>
                                </router-link>
                            </div>

                            <div
                                v-if="getCurrentItemSubmenu(key)"
                                class="sidebar__menu_list"
                                :class="{
                                    active__submenu_child: getCurrentSidebarMenuActive(key)
                                }"
                                @click.stop="updateIsShowItemSubmenuAction(key, getSubmenuKey(menuItem, key))"
                            >
                                <div class="sidebar__menu_link">
                                    <i
                                        class="sidebar__menu_icon--flex"
                                        v-html="getIconTemplate(menuItem[1])" 
                                    ></i>
                                    <span class="sidebar__menu_link--text">{{ menuItem[0] }}</span>
                                    <i 
                                        class="chevron__down"
                                        :class="{ rotate: getIsShowItemSubmenu(key) }"
                                        v-html="getIconTemplate('icon-sidebar-arrow-down')"
                                    ></i>
                                </div>
                            </div>

                            <Transition name="submenu">
                                <ul
                                    v-if="menuItem[2] && getIsShowItemSubmenu(key) && !getInterfaceMenuShowBtn"
                                    class="sidebar__submenu"
                                >
                                    <li
                                        v-for="(submenuItem, submenuKey, submenuIndex) in menuItem[2]"
                                        :key="`submenuItem-${submenuKey}${submenuIndex}`"
                                        class="sidebar__submenu_list"
                                        :class="{ active__submenu: getSidebarMenuActive === submenuKey }"
                                        @click.stop="updateActiveMenuShowStatus(submenuKey, 'submenu')"
                                    >
                                        <router-link
                                            :to="getRouterLinkPath(key, submenuKey)"
                                            class="sidebar__submenu_link"
                                        >
                                            <i
                                                v-if="getSidebarMenuActive === submenuKey"
                                                class="sidebar__icon--active"
                                                v-html="getIconTemplate('icon-circle-small-alt')"
                                            ></i>
                                            <i
                                                v-else
                                                class="sidebar__icon--not-active"
                                                v-html="getIconTemplate('icon-circle-small')"
                                            ></i>
                                            {{ submenuItem }}
                                        </router-link>
                                    </li>
                                </ul>
                                <ul
                                    v-else-if="menuItem[2] && getIsShowItemSubmenu(key) && getInterfaceMenuShowBtn"
                                    class="sidebar__submenu_popup"
                                >
                                    <li
                                        v-for="(submenuItem, submenuKey, submenuIndex) in menuItem[2]"
                                        :key="`submenuItem-${submenuKey}${submenuIndex}`"
                                        class="sidebar__submenu_popup-item"
                                        :class="{ active__submenu: getSidebarMenuActive === submenuKey }"
                                        @click.stop="updateActiveMenuShowStatus(submenuKey, 'submenu')"
                                    >
                                        <router-link
                                            :to="getRouterLinkPath(key, submenuKey)"
                                            class="sidebar__submenu_popup-link"
                                        >
                                            {{ submenuItem }}
                                        </router-link>
                                    </li>
                                </ul>
                            </Transition>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import { getLifetimeCasesLink } from "@/router";
import { mapActions, mapGetters } from "vuex";
import { icons } from "@/mixins";

export default {
    name: "TheSidebar",
    mixins: [icons],
    data() {
        return {
            lifetimeCasesLink: getLifetimeCasesLink(),
            sidebarMenu: {
                lifetimeCasesList: [
                    "Прижизненные исследования",
                    "hand-holding-heart",
                    {
                        lifetimeCases: "Журнал",
                        lifetimeCasesSamplePreparation: "Пробоподготовка"
                    }
                ],
                // mortemCasesList: [
                //     "Посмертные исследования",
                //     "hand-holding-medical",
                //     {
                //         mortemCases: "Журнал",
                //         // mortemCasesSamplePreparation: "Пробоподготовка"
                //     }
                // ],
                // mortemCases: ["Посмертные исследования", "hand-holding-medical"],
                // archive: [
                //     "Архив",
                //     "archive",
                //     {
                //         lifetimeCasesArchive: "Прижизненный",
                //         mortemCasesArchive: "Посмертный",
                //     }
                // ],
                reference: [
                    "Справочники",
                    "reference",
                ],
                settings: [
                    "Настройки",
                    "setting",
                ]
            },
        };
    },
    computed: {
        ...mapGetters([
            "getInterfaceMenuShowBtn",
            "getSidebarMenuActive",
            "getIsShowArchiveSubmenu",
            "getIsShowLifetimeCasesListSubmenu",
            "getIsShowMortemCasesListSubmenu",
            "getIsShowReferenceSubmenu",
            "getIsShowSettingsSubmenu",
        ]),
    },
    methods: {
        ...mapActions([
            "updateSidebarMenuActive",
            "updateLifetimeArchiveShowBtnStatus",
            "updateMortemArchiveShowBtnStatus",
            "updateArchiveShowBtnStatuses",
            "updateIsShowArchiveSubmenu",
            "updateIsShowLifetimeCasesListSubmenu",
            "updateIsShowMortemCasesListSubmenu",
            "updateMenuShowBtnStatus",
            "updateIsShowReferenceSubmenu",
            "updateIsShowSettingsSubmenu",
        ]),
        getConditionForSidebarMenuItemActiveClass(menuItemKey) {
            switch (menuItemKey) {
                // case "mortemCasesList":
                case "lifetimeCasesList":
                case "archive":
                case "reference":
                case "settings":
                    return { active: this.getSidebarMenuActive === menuItemKey };
                // на случай если понадобится показывать сперва общую страницу для всех подпунктов меню, а не конкретный пункт подменю
                // case "reference":
                //     return "";
                default:
                    break;
            }
        },
        getSubmenuKey(menuItem, menuItemKey) {
            switch (menuItemKey) {
                // case "mortemCasesList":
                case "lifetimeCasesList":
                case "archive":
                    return Object.keys(menuItem[2])[0];
                default:
                    break;
            }
        },
        getRouterLinkPath(menuItemKey, submenuItemKey) {
            switch (menuItemKey) {
                // case "mortemCasesList":
                case "lifetimeCasesList":
                case "archive":
                    return { name: `${submenuItemKey}` };
                default:
                    break;
            }
        },
        getCurrentSidebarMenuActive(menuItemKey) {
            switch (menuItemKey) {
                case "lifetimeCasesList":
                    return this.getSidebarMenuActive === "lifetimeCases" || this.getSidebarMenuActive === "lifetimeCasesSamplePreparation";
                // case "mortemCasesList":
                //     return this.getSidebarMenuActive === "mortemCases" || this.getSidebarMenuActive === "mortemCasesSamplePreparation";
                case "archive":
                    return this.getSidebarMenuActive === "lifetimeCasesArchive" || this.getSidebarMenuActive === "mortemCasesArchive";
                default:
                    break;
            }
        },
        getCurrentItemSubmenu(menuItemKey) {
            switch (menuItemKey) {
                // case "mortemCasesList":
                case "lifetimeCasesList":
                case "archive":
                    return true;
                default:
                    break;
            }
        },
        getIsShowItemSubmenu(menuItemKey) {
            switch (menuItemKey) {
                case "lifetimeCasesList":
                    return this.getIsShowLifetimeCasesListSubmenu;
                // case "mortemCasesList":
                //     return this.getIsShowMortemCasesListSubmenu;
                case "archive":
                    return this.getIsShowArchiveSubmenu;
                default:
                    break;
            }
        },
        updateIsShowItemSubmenuAction(menuItemKey, submenuItemKey) {
            this.updateActiveMenuShowStatus(submenuItemKey);
            switch (menuItemKey) {
                case "lifetimeCasesList":
                    if (this.getCurrentSidebarMenuActive(menuItemKey)) {
                        this.updateIsShowMortemCasesListSubmenu(false);
                        this.updateIsShowArchiveSubmenu(false);
                        this.updateIsShowReferenceSubmenu(false);
                        this.updateIsShowSettingsSubmenu(false);
                    }
                    this.getIsShowLifetimeCasesListSubmenu ? this.updateIsShowLifetimeCasesListSubmenu(false) : this.updateIsShowLifetimeCasesListSubmenu(true);
                    break;
                // case "mortemCasesList":
                //     if (this.getCurrentSidebarMenuActive(menuItemKey)) {
                //         this.updateIsShowLifetimeCasesListSubmenu(false);
                //         this.updateIsShowArchiveSubmenu(false);
                //         this.updateIsShowReferenceSubmenu(false);
                //         this.updateIsShowSettingsSubmenu(false);
                //     }
                //     this.getIsShowMortemCasesListSubmenu ? this.updateIsShowMortemCasesListSubmenu(false) : this.updateIsShowMortemCasesListSubmenu(true);
                //     break;
                case "archive":
                    if (this.getCurrentSidebarMenuActive(menuItemKey)) {
                        this.updateIsShowMortemCasesListSubmenu(false);
                        this.updateIsShowLifetimeCasesListSubmenu(false);
                        this.updateIsShowReferenceSubmenu(false);
                        this.updateIsShowSettingsSubmenu(false);
                    }
                    this.getIsShowArchiveSubmenu ? this.updateIsShowArchiveSubmenu(false) : this.updateIsShowArchiveSubmenu(true);
                    break;
                default:
                    break;
            }
        },
        updateActiveMenuShowStatus(menuItemKey, sidebarType) {
            if (sidebarType === 'submenu') {
                this.updateSidebarMenuActive(menuItemKey);
                if (this.getInterfaceMenuShowBtn) {
                    this.updateIsShowLifetimeCasesListSubmenu(false);
                    this.updateIsShowMortemCasesListSubmenu(false);
                    this.updateIsShowArchiveSubmenu(false);
                    this.updateIsShowReferenceSubmenu(false);
                    this.updateIsShowSettingsSubmenu(false);
                }
            }
            switch (menuItemKey) {
                case "lifetimeCases":
                case "lifetimeCasesSamplePreparation":
                    this.updateIsShowMortemCasesListSubmenu(false);
                    this.updateIsShowArchiveSubmenu(false);
                    this.updateIsShowReferenceSubmenu(false);
                    this.updateIsShowSettingsSubmenu(false);
                    break;
                case "mortemCases":
                case "mortemCasesSamplePreparation":
                    this.updateIsShowLifetimeCasesListSubmenu(false);
                    this.updateIsShowArchiveSubmenu(false);
                    this.updateIsShowReferenceSubmenu(false);
                    this.updateIsShowSettingsSubmenu(false);
                    break;
                case "lifetimeCasesArchive":
                case "mortemCasesArchive":
                    this.updateIsShowLifetimeCasesListSubmenu(false);
                    this.updateIsShowMortemCasesListSubmenu(false);
                    this.updateIsShowReferenceSubmenu(false);
                    this.updateIsShowSettingsSubmenu(false);
                    break;
                case "staff":
                case "organizations":
                case "departments":
                case "doctors":
                case "localizations":
                    this.updateIsShowLifetimeCasesListSubmenu(false);
                    this.updateIsShowMortemCasesListSubmenu(false);
                    this.updateIsShowArchiveSubmenu(false);
                    this.updateIsShowSettingsSubmenu(false);
                    break;
                case "settingsLifetime":
                    this.updateIsShowLifetimeCasesListSubmenu(false);
                    this.updateIsShowMortemCasesListSubmenu(false);
                    this.updateIsShowArchiveSubmenu(false);
                    this.updateIsShowReferenceSubmenu(false);
                    break;
                default:
                    this.updateIsShowLifetimeCasesListSubmenu(false);
                    this.updateIsShowMortemCasesListSubmenu(false);
                    this.updateIsShowArchiveSubmenu(false);
                    this.updateIsShowReferenceSubmenu(false);
                    this.updateIsShowSettingsSubmenu(false);
                    break;
            }
        },
        closePopupSidebarSubmenu(event) {
            if (this.getInterfaceMenuShowBtn) {
                if (!this.getIsShowArchiveSubmenu
                    && !this.getIsShowLifetimeCasesListSubmenu
                    && !this.getIsShowMortemCasesListSubmenu
                    && !this.getIsShowReferenceSubmenu
                    && !this.getIsShowSettingsSubmenu) {
                    return;
                }
                if (event.target.closest(".sidebar__menu_list") !== null) {
                    return;
                }
                this.updateIsShowItemSubmenuAction();
            }
        }
    },
    mounted() {
        this.$nextTick(function() {
            window.addEventListener("click", this.closePopupSidebarSubmenu, true);
        });
    },
    beforeUnmount() {
        window.removeEventListener("click", this.closePopupSidebarSubmenu, true);
    }
};
</script>

<style lang="sass">
@import "@/assets/css/_vars.sass"

.main__left_scroll
    position: relative
    overflow: visible
    padding: 24px 0 24px 16px
    width: 100%
    height: 100%
    .sidebar__container
        height: 100%
        display: flex
        flex-direction: column
        justify-content: space-between
        &_down
            display: flex
            flex-direction: column
            gap: 16px
            font-weight: 700
            font-size: 14px
            line-height: 17px
            padding-left: 24px
            width: fit-content
            a
                color: $sidebarMenuTextColor
                &:hover
                    color: $sidebarMenuTextHoverColor
                &:hover path
                    fill: $sidebarMenuTextHoverColor !important
        &_down-item
            display: flex
            gap: 12px
        .active__sidebar_down span
            color: $sidebarDownActiveTextColor
            &:hover
                color: $sidebarDownActiveTextColor
        .active__sidebar_down path
            fill: $sidebarDownActiveTextColor !important
        .active__sidebar_down:hover
            path
                fill: $sidebarDownActiveTextColor !important
    .header__left
        display: flex
        justify-content: space-between
        align-items: center
        background: $adminBackgroundMenuPanelColor
        transition: all 0.2s ease-in-out 0s
        padding-bottom: 40px
        padding-left: 11px
        .header__left_logo
            transition: all 0.4s ease
            img
                display: flex
                justify-content: center
                align-items: center
                width: 142px
                height: 24px
        .mob_logo
            transition: all 0.4s ease
            display: none
            justify-content: center
            align-items: center
            img
                width: 24px
                height: 24px
        .chevron__left
            display: flex
            justify-content: center
            align-items: center
            font-size: 14px
            color: $toggleBtnBGColor
            transition: all 0.2s ease-in-out 0s
            cursor: pointer
            padding-right: 16px
            &:hover
                color: $toggleBtnHoverColor
    .sidebar
        color: $sidebarMenuTextColor
        &__menu-hidden
            .sidebar__menu_list
                width: fit-content
                border-radius: 8px
            .sidebar__submenu
                display: none
            .chevron__down
                display: none
        &__submenu
            margin-top: 4px
            &_list
                align-items: center
                display: flex
                justify-content: flex-start
                transition: all 0.3s ease-in-out 0s
                width: 100%
                margin-bottom: 4px
            &_link
                display: flex
                justify-content: flex-start
                align-content: center
                font-size: 13px
                color: $sidebarSubmenuTextColor
                padding: 12px 16px 12px 20px
                border-top-left-radius: 8px
                border-bottom-left-radius: 8px
                width: 100%
                gap: 16px
                align-items: center
                &:hover
                    color: $sidebarSubmenuTextHoverColor
                    background-color: $sidebarSubmenuBackgroundHoverColor
                    & .sidebar__icon--not-active svg path
                        fill: $deepPurple
        &__submenu_popup
            width: 224px
            padding: 4px
            border-radius: 8px
            display: flex
            flex-direction: column
            gap: 4px
            position: absolute
            background: $sidebarSubmenuPopupBackgroundColor
            box-shadow: 0px 0px 8px 0px rgba(210, 207, 213, 0.75)
            top: -4px
            right: -231px
            &-item
                align-items: center
                display: flex
                justify-content: flex-start
                transition: all 0.3s ease-in-out 0s
                width: 100%
                border-radius: 4px
                &:hover
                    color: $sidebarSubmenuTextHoverColor
                    background: $sidebarSubmenuBackgroundHoverColor
            &-link
                display: flex
                justify-content: flex-start
                align-content: center
                font-size: 13px
                color: $sidebarSubmenuTextColor
                padding: 12px 16px
                width: 100%
                &:hover
                    color: $sidebarSubmenuTextHoverColor
                    background: $sidebarSubmenuBackgroundHoverColor
                    border-radius: 4px
        &__menu
            font-weight: 500
            font-size: 14px
            line-height: 17px
            &_list
                display: flex
                align-items: center
                border-top-left-radius: 8px
                border-bottom-left-radius: 8px
                justify-content: flex-start
                transition: all 0.2s ease-in-out 0s
                margin-bottom: 4px
                .sidebar__menu_link span
                    display: inline-block
                    width: 120px
                &:hover
                    color: $sidebarMenuTextHoverColor
                    background-color: $sidebarMenuBackgroundHoverColor
                &:hover span
                    color: $sidebarMenuTextHoverColor
                &:hover .sidebar__menu_link path
                    // проставил important, т.к. в новой svg стили прописаны инлайн и у них большая приоритетность
                    fill: $sidebarMenuTextHoverColor !important
                    stroke: $sidebarMenuTextHoverColor !important
                a
                    color: $sidebarMenuTextColor
                    transition: all 0.2s ease-in-out 0s
                .sidebar__menu_icon
                    display: flex
                    width: 40px
                    justify-content: center
                    align-items: center
            .active__submenu_parent
                background-color: $sidebarMenuBackgroundActiveColor
                &:hover span
                        color: $sidebarActiveMenuTextColor
                .sidebar__menu_link
                    color: $sidebarActiveMenuTextColor
                    path
                        fill: $sidebarActiveMenuTextColor !important
                        stroke: $sidebarActiveMenuTextColor !important
            .active__submenu_child
                background-color: $sidebarMenuBackgroundActiveColor
                border-top-left-radius: 8px
                border-bottom-left-radius: 8px
                &:hover
                    color: $sidebarActiveMenuTextColor
                    background-color: $sidebarMenuBackgroundActiveColor
                    &:hover span
                        color: $sidebarActiveMenuTextColor
                    &:hover .sidebar__menu_link path
                        stroke: $sidebarActiveMenuTextColor !important
                        fill: $sidebarActiveMenuTextColor !important
                .sidebar__menu_link
                    color: $sidebarActiveMenuTextColor
                    path
                        fill: $sidebarActiveMenuTextColor !important
                        stroke: $sidebarActiveMenuTextColor !important
                a
                    color: $sidebarActiveMenuTextColor
            .active
                .sidebar__menu_list
                    background-color: $sidebarMenuBackgroundActiveColor
                    & span
                        color: $sidebarActiveMenuTextColor
                    & path
                        fill: $sidebarActiveMenuTextColor !important
                        stroke: $sidebarActiveMenuTextColor !important
            .active__submenu
                width: 100%
                background: $sidebarSubmenuBackgroundActiveColor
                border-top-left-radius: 4px
                border-bottom-left-radius: 4px
                a
                    color: $sidebarActiveSubmenuTextColor
                    &:hover
                        color: $sidebarActiveSubmenuTextColor
                        border-top-left-radius: 4px
                        border-bottom-left-radius: 4px
            .active__submenu_child
                color: $sidebarActiveMenuTextColor
            &_link
                display: flex
                justify-content: center
                align-content: center
                align-items: center
                position: relative
                padding: 12px 16px
                color: $sidebarMenuTextColor
                gap: 12px
                &:hover
                    color: $sidebarMenuTextHoverColor
    .summary__title
        width: 100%
        display: flex
        justify-content: space-between
.chevron__down
    // position: absolute
    // left: 184px
    display: flex
    font-size: 14px
    transition: all 0.2s ease-in-out 0s
.rotate
    transform: rotate(180deg)
.submenu-enter-active,
.submenu-leave-active 
    transition: opacity 0.1s ease
.submenu-enter-from,
.submenu-leave-to 
    opacity: 0
.sidebar__menu_link--text
    font-size: 12px
    font-style: normal
    font-weight: 500
    line-height: 18px
.sidebar__menu_icon--flex
    display: flex
.sidebar__icon--active svg, .sidebar__icon--not-active svg
    width: 24px
    height: 24px
    display: flex
.sidebar__icon--active svg path
    fill: $deepPurple
.sidebar__icon--not-active svg path
    fill: $darkPurple
</style>