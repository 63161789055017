export const referenceColoringMethodFields = {
    data() {
        return {
            referenceColoringMethodTableDescriptionData: [
                { field: "active", header: "", columnWidth: "32px"},
                { field: "title", header: "Наименование метода окраски", columnWidth: "450px"},
                { field: "title_ending", header: "Краткое наименование", columnWidth: "160px"},
                { field: "code_nsi", header: "Код НСИ", columnWidth: "70px"},
                { field: "title_nsi", header: "Наименование в НСИ", columnWidth: "auto"},
            ],
        }
    },
}