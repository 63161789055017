import { lifetimeCasesTabsApi } from "@/api";

export default {
    state: {
        lifetimeGeneralData: null,
            // {
                // register_number: null,
                // register_timestamp: null,
                // cito: false,
                // status: "REGISTERED",
            // }
        lifetimeCasesGeneralArrivedDateInputFocus: false,
        lifetimeGeneralCitoStatus: false,
        lifetimeGeneralDate: null,
    },

    actions: {
        async updateLifetimeCaseRegistrationInformationDataById({ dispatch, rootGetters }) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                if (!rootGetters.getResponsibleStaffMemberSelectedName) {
                    return;
                }
                const updatedInformationData = {
                    responsible_staff: rootGetters.getResponsibleStaffMemberSelectedItemId,
                    responsible_staff_name: rootGetters.getResponsibleStaffMemberSelectedName
                }
                dispatch("fetchLifetimeCaseRegistrationInformationDataById", [token, updatedInformationData]);
            });
        },
        async fetchLifetimeCaseRegistrationInformationDataById({ dispatch, rootGetters }, [token, updatedInformationData]) {
            try {
                const informationData = await lifetimeCasesTabsApi.putLifetimeCaseInformationById(rootGetters.getLifetimeCaseId, updatedInformationData, token);
                if (typeof informationData === "number") {
                    return;
                }
                if (informationData) {
                    dispatch("addToastMessage", {code: 200, message: null});
                    dispatch("fetchLifetimeCaseById", [token, rootGetters.getLifetimeCaseId]);   
                }
            } catch (e) {
                console.error(e);
                // dispatch("setExceptionAccordingResponseCode", e.status);
            }
        },
        updateLifetimeGeneralDate({ commit }, lifetimeGeneralDate) {
            commit("changeLifetimeGeneralDate", lifetimeGeneralDate);
        },
        updateLifetimeGeneralData({ commit }, lifetimeGeneralData) {
            commit("changeLifetimeGeneralData", lifetimeGeneralData);
        },
        updateLifetimeCasesGeneralArrivedDateInputFocus({ commit }, lifetimeCasesGeneralArrivedDateInputFocus) {
            commit("changeLifetimeCasesGeneralArrivedDateInputFocus", lifetimeCasesGeneralArrivedDateInputFocus);
        },
        updateLifetimeGeneralCitoStatus({ commit }, lifetimeGeneralCitoStatus) {
            commit("changeLifetimeGeneralCitoStatus", lifetimeGeneralCitoStatus);
        },
        clearLifetimeGeneralData({ commit }) {
            commit("changeLifetimeGeneralData", null);
            commit("changeLifetimeGeneralDate", null);
            commit("changeLifetimeCasesGeneralArrivedDateInputFocus", false);
            commit("changeLifetimeGeneralCitoStatus", false);
        }
    },

    mutations: {
        changeLifetimeGeneralDate(state, lifetimeGeneralDate) {
            state.lifetimeGeneralDate = lifetimeGeneralDate;
        },
        changeLifetimeGeneralData(state, lifetimeGeneralData) {
            if (lifetimeGeneralData !== null) {
                state.lifetimeGeneralData = Object.assign({}, state.lifetimeGeneralData, lifetimeGeneralData);
                return;
            }
            state.lifetimeGeneralData = lifetimeGeneralData;
        },
        changeLifetimeCasesGeneralArrivedDateInputFocus(state, lifetimeCasesGeneralArrivedDateInputFocus) {
            state.lifetimeCasesGeneralArrivedDateInputFocus = lifetimeCasesGeneralArrivedDateInputFocus;
        },
        changeLifetimeGeneralCitoStatus(state, lifetimeGeneralCitoStatus) {
            state.lifetimeGeneralCitoStatus = lifetimeGeneralCitoStatus;
        },
    },

    getters: {
        getLifetimeGeneralDate(state) {
            return state.lifetimeGeneralDate;
        },
        getLifetimeGeneralData(state) {
            return state.lifetimeGeneralData;
        },
        getLifetimeCasesGeneralArrivedDateInputFocus(state) {
            return state.lifetimeCasesGeneralArrivedDateInputFocus;
        },
        getLifetimeGeneralCitoStatus(state) {
            return state.lifetimeGeneralCitoStatus;
        }
    }
}
