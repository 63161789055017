import { api } from "@/api";

export default {
    state: {
        settingsEmployeesList: [],
        settingsEmployeesTotalPages: null,
        isSettingsEmployeesLoading: false,
        newSettingsEmployees: null,
        employeeByIpaSearch: null,
    },
    actions: {
        async fetchSettingsEmployess({ dispatch, commit }, queryParams) {
            commit('updateSettingsEmployeesLoader', true)
            try {
                const token = await dispatch('getAccessToken')
                if (token) {
                    const employees = await api.getSettingsReferenceEmployees(queryParams, token);
                    commit('changeSettingsEmployees', employees.results)
                    commit(
                        'changeSettingsEmployeesTotalPages',
                        employees.total_pages
                    )
                    commit('updateSettingsEmployeesLoader', false)
                } else {
                    dispatch('getRedirectAuthAfterInvalidRefreshTokenAction')
                    commit('updateSettingsEmployeesLoader', false)
                    return
                }
            } catch (error) {
                console.log(error)
            }
        },
        async searchSettingsEmployeesByIpa({ dispatch, commit }, queryParams) {
            commit('updateSettingsEmployeesLoader', true)
            try {
                const token = await dispatch('getAccessToken')
                if (token) {
                    const employees = await api.getSettingsReferenceEmployees(queryParams, token);
                    commit('changeEmployeeByIpaSearch', employees.results[0])
                    commit('updateSettingsEmployeesLoader', false)
                } else {
                    dispatch('getRedirectAuthAfterInvalidRefreshTokenAction')
                    commit('updateSettingsEmployeesLoader', false)
                    return
                }
            } catch (error) {
                console.log(error)
            }
        },
        async fetchMoreSettingsEmployees({ dispatch, commit }, queryParams) {
            commit('updateSettingsEmployeesLoader', true)
            try {
                const token = await dispatch('getAccessToken')
                if (token) {
                    const employees =
                        await api.getSettingsReferenceEmployees(
                            queryParams,
                            token
                        )
                    commit('updateMoreSettingsEmployees', employees.results)
                    commit('updateSettingsEmployeesLoader', false)
                } else {
                    dispatch('getRedirectAuthAfterInvalidRefreshTokenAction')
                    commit('updateSettingsEmployeesLoader', false)
                }
            } catch (error) {
                console.log(error)
                commit('updateSettingsEmployeesLoader', false)
            }
        },
        async addSettingsEmployees({ dispatch, commit }, employee) {
            try {
                const token = await dispatch('getAccessToken');
                if (token) {
                    const employeeData = await api.postSettingsReferenceEmployees(employee, token);
                    if (typeof employeeData === 'object') {
                        commit('addNewSettingsEmployees', employeeData);
                        return true;  // Возвращает true при успешной операции
                    } else {
                        return false;
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return false;
                }
            } catch (error) {
                console.log(error);
                return false;
            }
        },
        async editSettingsEmployees({ dispatch, commit }, [employee,employeeId]) {
            try {
                const token = await dispatch('getAccessToken')
                if (token) {
                    const employeeData =
                        await api.patchSettingsReferenceEmployees(
                            employee,
                            employeeId,
                            token
                        )
                    if (typeof employeeData === 'object') {
                        commit('addNewSettingsEmployees', employeeData);
                        return true;  // Возвращает true при успешной операции
                    } else {
                        return false;
                    }
                } else {
                    dispatch('getRedirectAuthAfterInvalidRefreshTokenAction')
                    return false;
                }
            } catch (error) {
                console.log(error);
                return false;
            }
        },
        clearSettingsEmployeesByIpa({ commit }) {
            commit('changeEmployeeByIpaSearch', null)
        },
    },
    mutations: {
        changeSettingsEmployees(state, settingsEmployeesList) {
            state.settingsEmployeesList = settingsEmployeesList
        },
        changeEmployeeByIpaSearch(state, employeeByIpaSearch) {
            state.employeeByIpaSearch = employeeByIpaSearch
        },
        updateMoreSettingsEmployees(state, settingsEmployeesList) {
            state.settingsEmployeesList = [
                ...state.settingsEmployeesList,
                ...settingsEmployeesList,
            ]
        },
        changeSettingsEmployeesTotalPages(state, settingsEmployeesTotalPages) {
            state.settingsEmployeesTotalPages = settingsEmployeesTotalPages
        },
        updateSettingsEmployeesLoader(state, condition) {
            state.isSettingsEmployeesLoading = condition
        },
        addNewSettingsEmployees(state, employee) {
            state.newSettingsEmployees = employee;
        },
        updateSettingsEmployees(state, employee) {
            const index = state.settingsEmployeesList.findIndex(
                (item) => item.id === employee.id
            )
            if (index !== -1) {
                const updatedEmployee = { ...employee } // Создаем копию объекта employee
                const uniqueId = Object.values(updatedEmployee).join('_') // Создаем уникальный идентификатор на основе значений объекта
                updatedEmployee.uniqueId = uniqueId // Добавляем новое поле uniqueId к объекту
                state.settingsEmployeesList.splice(
                    index,
                    1,
                    updatedEmployee
                ) // Заменяем объект в массиве
            }
        },
    },
    getters: {
        getSettingsEmployees(state) {
            return state.settingsEmployeesList
        },
        getEmployeeByIpaSearch(state) {
            return state.employeeByIpaSearch
        },
        getSettingsEmployeesTotalPages(state) {
            return state.settingsEmployeesTotalPages
        },
        getSettingsEmployeesLoading(state) {
            return state.isSettingsEmployeesLoading
        },
        getNewSettingsEmployees(state) {
            return state.newSettingsEmployees
        }
    },
}
