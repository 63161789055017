import { lifetimeApi, lifetimeApiWS } from "@/api";

export default {
    state: {
        stages: [],
        isStageChanged: false,
        dataForTask: null,
        isTaskDataLoading: false,
        samplePreparationJournalWsConnection: null,
    },
    actions: {
        async connectToSamplePreparationJournalWS({ state, commit, dispatch, rootGetters }, payload) {
            // Проверка наличия параметров, была ошибка с этим во время тестирования
            const [getCassetteAction, queryParams] = payload || [];

            console.warn('Attempting to connect to SamplePreparationJournal WebSocket...');
            
            const token = await dispatch("getAccessToken");
            
            if (!token) { 
                console.warn("Token not found, redirecting to auth...");
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                return;
            }

            const socket = state.samplePreparationJournalWsConnection;

            if (socket && (socket.readyState === WebSocket.OPEN)) {
                console.warn("WebSocket is already connected");
                return;
            }

            if (socket && (socket.readyState === WebSocket.CONNECTING)) {
                console.warn("WebSocket is connecting...");
                return;
            }

            // Todo: стоит улучшить
            if (socket && (socket.readyState === WebSocket.CLOSING || socket.readyState === WebSocket.CLOSED)) {
                console.warn("Waiting for WebSocket to close...");
                await new Promise((resolve) => {
                    const handleClose = () => {
                        console.warn('WebSocket closed, attempting to reconnect...');
                        resolve();
                    };
                    socket.addEventListener('close', handleClose);
                    // Принудительно закрываем, если в состоянии CLOSING
                    if (socket.readyState === WebSocket.CLOSING) {
                        socket.close();
                    }
                    // Дополнительная уверенность
                    if (socket.readyState === WebSocket.CLOSED) {
                        resolve();
                    }
                });
            }

            try {
                const newSocket = await lifetimeApiWS.requestSamplePreparationJournal(token);
                commit("changeSamplePreparationJournalWsConnection", newSocket);

                newSocket.onmessage = (event) => {
                    console.warn("WebSocket message received:", event.data);
                    const data = JSON.parse(event.data);
                    if (data.response_status === 403) {
                        console.warn("Received 403 response, attempting to reconnect...");
                        dispatch("disconnectFromSamplePreparationJournalWS");
                        dispatch("reconnectSamplePreparationJournalWS");
                    } else if (data?.data?.user_id) {
                        if (rootGetters.returnLifetimeCaseById.id) {
                            dispatch("fetchSamplePreparationCassettes", [{ lifecase_id: rootGetters.returnLifetimeCaseById.id }, true]);
                        } else if (getCassetteAction) {
                            getCassetteAction([queryParams, true]); 
                        }
                    }
                };

                newSocket.onclose = (event) => { 
                    console.warn("WebSocket connection closed:", event);
                    dispatch("reconnectSamplePreparationJournalWS");
                };

                newSocket.onerror = (error) => { 
                    console.error("WebSocket error:", error);
                    dispatch("reconnectSamplePreparationJournalWS");
                };

                console.warn("WebSocket connection established");
            } catch (error) { 
                console.error("Error connecting to WebSocket:", error);
                dispatch("reconnectSamplePreparationJournalWS");
            }
        },
        async reconnectSamplePreparationJournalWS({ dispatch }) { 
            console.warn("Reconnecting WebSocket...");
            setTimeout(() => {
                dispatch("connectToSamplePreparationJournalWS");
            }, 1000);
        },
        disconnectFromSamplePreparationJournalWS({ commit, state }) { 
            const socket = state.samplePreparationJournalWsConnection;
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.close();
                console.warn("WebSocket connection closed");
            } else {
                console.warn("WebSocket was already closed or not established");
            }
            commit("changeSamplePreparationJournalWsConnection", null);
        },
        async fetchLifetimeStages({ dispatch, commit }) {
            const token = await dispatch("getAccessToken");
            if (token) {
                const stages = await lifetimeApi.getLifetimeStages(token);
                if (typeof stages === "object") {
                    commit("updateLifetimeStages", stages);
                }
            } else {
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
            }
        },
        async fetchStagesTask({ dispatch, commit }, taskData) {
            try {
                const token = await dispatch("getAccessToken");
                commit("updateIsTaskDataLoading", true); 
                if (token) {
                    const task = await lifetimeApi.getStagesTask(taskData, token);
                    if (typeof task === "object") {
                        commit("updateStageData", task);
                        commit("updateIsTaskDataLoading", false); 
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateIsTaskDataLoading", false);
                }
            } catch (error) {
                console.error(error);
                commit("updateIsTaskDataLoading", false); 
            }
        },
        async returnInitialStage({ dispatch, commit }, data) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const stage = await lifetimeApi.patchReturnInitialStage(data, token);
                    if (typeof stage === "object") {
                        commit("updateSamplePreparationStageChangedCondition");
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                }
            } catch (error) {
                console.error(error);
            }
        },
        async returnInitialMicropreparationStage({ dispatch, commit }, data) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const stage = await lifetimeApi.patchReturnInitialMicropraparationStage(data, token);
                    if (typeof stage === "object") {
                        commit("updateSamplePreparationStageChangedCondition");
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                }
            } catch (error) {
                console.error(error);
            }
        },
        async startWiringStage({ dispatch, commit }, wiringData) {
            const token = await dispatch("getAccessToken");
            if (token) {
                const stages = await lifetimeApi.startWiringStage(wiringData, token);
                if (typeof stages === "object") {
                    commit("updateSamplePreparationStageChangedCondition");
                }
            } else {
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
            }
        },
        async startSamplePreparationCito({ dispatch, commit }, samplePreparationData) {
            const token = await dispatch("getAccessToken");
            if (token) {
                const stages = await lifetimeApi.startSamplePreparationCito(samplePreparationData, token);
                if (typeof stages === "object") {
                    commit("updateSamplePreparationStageChangedCondition");
                }
            } else {
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
            }
        },
        async startFillingStage({ dispatch, commit }, fillingData) {
            const token = await dispatch("getAccessToken");
            if (token) {
                const stages = await lifetimeApi.startFillingStage(fillingData, token);
                if (typeof stages === "object") {
                    commit("updateSamplePreparationStageChangedCondition");
                }
            } else {
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
            }
        },
        async startMicrotomiaStage({ dispatch, commit }, microtomiaData) {
            const token = await dispatch("getAccessToken");
            if (token) {
                const stages = await lifetimeApi.startMicrotomiaStage(microtomiaData, token);
                if (typeof stages === "object") {
                    commit("updateSamplePreparationStageChangedCondition");
                }
            } else {
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
            }
        },
        async startColoringStage({ dispatch, commit }, coloringData) {
            const token = await dispatch("getAccessToken");
            if (token) {
                const stages = await lifetimeApi.startColoringStage(coloringData, token);
                if (typeof stages === "object") {
                    commit("updateSamplePreparationStageChangedCondition");
                }
            } else {
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
            }
        },
        async putEndDecaltinationStage({ dispatch, commit }, decaltinationData) {
            const token = await dispatch("getAccessToken");
            if (token) {
                const stages = await lifetimeApi.putEndDecaltinationStage(decaltinationData, token);
                if (typeof stages === "object") {
                    commit("updateSamplePreparationStageChangedCondition");
                }
            } else {
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
            }
        },
        async putEndDoficsationStage({ dispatch, commit }, doficsationData) {
            const token = await dispatch("getAccessToken");
            if (token) {
                const stages = await lifetimeApi.putEndDoficsationStage(doficsationData, token);
                if (typeof stages === "object") {
                    commit("updateSamplePreparationStageChangedCondition");
                }
            } else {
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
            }
        },
    },
    mutations: {
        changeSamplePreparationJournalWsConnection(state, samplePreparationJournalWsConnection) {
            console.warn("Updating WebSocket connection state"); 
            state.samplePreparationJournalWsConnection = samplePreparationJournalWsConnection;
        },
        updateLifetimeStages(state, stages) {
            state.stages = stages;
        },
        updateStageData(state, task) {
            state.dataForTask = task;
        },
        updateIsTaskDataLoading(state, condition) {
            state.isTaskDataLoading = condition;
        },
        updateSamplePreparationStageChangedCondition(state) {
            state.isStageChanged = !state.isStageChanged;
        }
    },
    getters: {
        getLifetimeStages(state) {
            return state.stages;
        },
        getDataForTask(state) {
            return state.dataForTask;
        },
        getStageChangedCondition(state) {
            return state.isStageChanged;
        },
        getIsTaskDataLoading(state) {
            return state.isTaskDataLoading;
        },
    },
};
