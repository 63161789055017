import queryString from "query-string";

export const getBaseEndpoint = () => process.env.VUE_APP_BASE_URL;
export const getAPIEndpoint = () => process.env.VUE_APP_API_BASE_URL;
export const getAPIWebSocketEndpoint = () => process.env.VUE_APP_API_WS_URL;
export const getAPIEndpointDictionary = () => "dictionaries";
export const getAPIEndpointDictionarySettings = () => "dictionaries";
export const getAPIEndpointSettings = () => "settings";
export const getAuthToken = () => [getAPIEndpoint(), "auth", "token/"].join("/");
export const getRefreshAuthToken = () => `${getAuthToken()}refresh/`;

console.debug(`
    VUE_APP_BASE_URL: ${getBaseEndpoint()},
    VUE_APP_API_BASE_URL: ${getAPIEndpoint()},
    VUE_APP_API_WS_URL: ${getAPIWebSocketEndpoint()}
`)

export const getParams = (page, page_size, search, register_timestamp__gte, register_timestamp__lte, ordering, status__status) => (url) => {
    const path = queryString.stringifyUrl({
        url,
        query: {
            page,
            page_size,
            search,
            register_timestamp__gte,//от
            register_timestamp__lte, //до
            ordering,
            status__status
        }
    },
    {
        skipEmptyString: true
    });
    return path;
};
export const getLifetimeCasesParams = (params) => (url) => {
    const path = queryString.stringifyUrl({
        url,
        query: params
    },
    {
        skipEmptyString: true
    });
    return path;
};

export const getSamplePreparationParams = (queryParams) => (url) => {
    const path = queryString.stringifyUrl({
        url,
        query: queryParams
    },
    {
        skipEmptyString: true
    });
    return path;
}


//LIFETIME CASE - register number

// Сгенерировать новый регистрационный номер
export const putLifetimeCaseRegisterNumberNewPath = () => `${getAPIEndpoint()}/registration-number/`;

// Продлить аренду регистрационного номера
export const postLifetimeCaseRegisterNumberExtendPath = (registerNumberId) => `${getAPIEndpoint()}/registration-number/${registerNumberId}/`;

// Аннулирует аренду регистрационного номера
export const postLifetimeCaseRegisterNumberInvalidatePath = (registerNumberId) => `${getAPIEndpoint()}/registration-number/${registerNumberId}/`;

// Создает новый случай
export const postLifetimeCaseRegisterNumberNewCasePath = () => `${getLifeCasesPath()}`;

//LIFETIME CASE

export const getLifeCasesPath = () => [getAPIEndpoint(), "lifecase/"].join("/");
export const getLifetimeCasesPath = () => [getAPIEndpoint(), "case/"].join("/");
export const addLifetimeNewCasePath = () => `${getLifetimeCasesPath()}add/`;
export const getLifetimeCaseByIdPath = (lifetimeCaseId) => `${getLifeCasesPath()}${lifetimeCaseId}/`;
export const patchLifetimeCaseByIdPath = (lifetimeCaseId) => `${getLifeCasesPath()}${lifetimeCaseId}/`;

//LIFETIME CASE TAB - Patient

export const putLifetimeCasePatientByIdPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}patient_info_edit/`;

//LIFETIME CASE TAB - Biopsy

export const putLifetimeCaseBiopsyByIdPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}biopsy_info_edit/`;

//LIFETIME CASE TAB - Disease

export const putLifetimeCaseDiseaseByIdPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}disease_info_edit/`;

//LIFETIME CASE TAB - Comment

export const putLifetimeCaseCommentByIdPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}comment_info_edit/`;

//LIFETIME CASE TAB - RequestOrganization

export const putLifetimeCaseRequestOrganizationByIdPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}request_organization_info_edit/`;

//LIFETIME CASE TAB - RegistrationInformation

export const putLifetimeCaseInformationByIdPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}staff_info_edit/`;

//LIFETIME CASE - MacroDescriptionPhoto

export const getLifetimeCasesMacroDescriptionPhotosPath = (lifetimeCaseId) => `${getLifeCasesPath() + lifetimeCaseId}/image/`;
export const getLifetimePhotosArchivePath = (lifetimeCaseId) => `${getLifeCasesPath() + lifetimeCaseId}/image/archive`;
export const addLifetimeCasesMacroDescriptionPhotosPath = (lifetimeCaseId) => `${getLifeCasesPath() + lifetimeCaseId}/image/`;
export const deleteLifetimeCasesMacroDescriptionPhotoPath = (lifetimeCaseId, imageId) => `${getLifeCasesPath() + lifetimeCaseId}/image/${imageId}`;

//LIFETIME CASE - MacroDescriptionCutObject

export const getLifetimeCasesMacroDescriptionAllFlasksPath = (lifetimeCaseId) => `${getLifeCasesPath() + lifetimeCaseId}/flask/`; // Все флаконы в случае
export const addLifetimeCasesMacroDescriptionFlaskPath = (lifetimeCaseId) => `${getLifeCasesPath() + lifetimeCaseId}/flask/`; // Добавить флакон к случаю
export const getLifetimeCasesMacroDescriptionFlaskPath = (lifetimeCaseId, flaskId) => `${getLifeCasesPath() + lifetimeCaseId}/flask/${flaskId}/`; // Флакон в случае
export const editLifetimeCasesMacroDescriptionFlaskPath = (lifetimeCaseId, flaskId) => `${getLifeCasesPath() + lifetimeCaseId}/flask/${flaskId}/`; // Редактировать флакон случая (PUT, PATCH)
export const deleteLifetimeCasesMacroDescriptionFlaskPath = (lifetimeCaseId, flaskId) => `${getLifeCasesPath() + lifetimeCaseId}/flask/${flaskId}/`; // Удалить флакон

export const getLifetimeCasesMacroDescriptionAllCassettesPath = (lifetimeCaseId, flaskId) => `${getLifeCasesPath() + lifetimeCaseId}/flask/${flaskId}/cassette/`; // Все кассеты во флаконе
export const addLifetimeCasesMacroDescriptionCassettePath = () => `${getAPIEndpoint()}/material/cassette/`; // Добавить кассету к флакону
export const getLifetimeCasesMacroDescriptionCassettePath = (lifetimeCaseId, flaskId, cassetteId) => `${getLifeCasesPath() + lifetimeCaseId}/flask/${flaskId}/cassette/${cassetteId}/`; // Кассета у флакона
export const editLifetimeCasesMacroDescriptionCassettePath = (lifetimeCaseId, flaskId, cassetteId) => `${getLifeCasesPath() + lifetimeCaseId}/flask/${flaskId}/cassette/${cassetteId}/`; // Редактировать кассету у флакона (PUT, PATCH)
export const deleteLifetimeCasesMacroDescriptionCassettePath = (cassetteId) => `${getAPIEndpoint()}/material/cassette/${cassetteId}/`; // Удалить кассету

export const getLifetimeCasesMacroDescriptionAllMicropreparationsPath = (lifetimeCaseId, flaskId, cassetteId) => `${getLifeCasesPath() + lifetimeCaseId}/flask/${flaskId}/cassette/${cassetteId}/micropreparation/`; // Все микропрепараты в кассете
export const addLifetimeCasesMacroDescriptionMicropreparationPath = () => `${getAPIEndpoint()}/material/micropreparation/`; // Добавить микропрепарат к кассете
export const getLifetimeCasesMacroDescriptionMicropreparationPath = (lifetimeCaseId, flaskId, cassetteId, micropreparationId) => `${getLifeCasesPath() + lifetimeCaseId}/flask/${flaskId}/cassette/${cassetteId}/micropreparation/${micropreparationId}`; // Микропрепарат в кассете
export const editLifetimeCasesMacroDescriptionMicropreparationPath = () => `${getAPIEndpoint()}/material/`; // Редактировать микропрепарат в кассете (PUT, PATCH)
export const deleteLifetimeCasesMacroDescriptionMicropreparationPath = (micropreparationId) => `${getAPIEndpoint()}/material/micropreparation/${micropreparationId}/`; // Удалить микропрепарат

//SAMPLE PREPARATION
export const getSamplePreparationCassettePath = () => `${getAPIEndpoint()}/cassette/`;
export const getLifetimeStagesPath = () => `${getAPIEndpoint()}/material/stages/`;
export const getStagesTaskPath = () => `${getAPIEndpoint()}/material/stages/task/`;
export const putLifetimeWiringStagePath = () => `${getAPIEndpoint()}/material/stages/cassette/wiring/`;
export const putLifetimeStartSamplePreparationCitoPath = () => `${getAPIEndpoint()}/material/stages/cassette/sample_preparation/`;
export const putLifetimeFillingStagePath = () => `${getAPIEndpoint()}/material/stages/block/filling/`;
export const putLifetimeMicrotomiaStagePath = () => `${getAPIEndpoint()}/material/stages/micropreparation/microtomy/`;
export const putLifetimeColoringStagePath = () => `${getAPIEndpoint()}/material/stages/micropreparation/coloring/`;
export const putLifetimeDecaltinationStagePath = () => `${getAPIEndpoint()}/material/stages/cassette/decalcification/`;
export const putLifetimeDoficsationStagePath = () => `${getAPIEndpoint()}/material/stages/cassette/additional_fixation/`;
export const patchReturnInitialStagePath = () => `${getAPIEndpoint()}/material/stages/cassette`;
export const patchReturnInitialMicropraparationStagePath = () => `${getAPIEndpoint()}/material/stages/micropreparation`;

//MICROSCOPY
export const getLifetimeMicroPreparationsPath = () => `${getAPIEndpoint()}/cassette/`;
export const getLifetimeCasesMicroscopyMicropreparationsPhotosPath = (micropreparationId) => `${getAPIEndpoint()}/material/micropreparation/${ micropreparationId }/image/`;
export const addLifetimeCasesMicroscopyMicropreparationsPhotosPath = (micropreparationId) => `${getAPIEndpoint()}/material/micropreparation/${ micropreparationId }/image/`;
export const deleteLifetimeCasesMicroscopyMicropreparationsPhotosPath = (micropreparationId, imageId) => `${getAPIEndpoint()}/material/micropreparation/${ micropreparationId }/image/${ imageId }`;

//MORTEM CASE

export const getMortemCasesPath = () => [getAPIEndpoint(), "mortalcase/"].join("/");
export const addMortemNewCasePath = () => [getMortemCasesPath(), "add/"].join("/");
export const getMortemCaseByIdPath = (mortemCaseId) => `${getMortemCasesPath()}view/${mortemCaseId}/`;
export const patchMortemCaseByIdPath = (mortemCaseId) => `${getMortemCasesPath()}edit/${mortemCaseId}/`;

// ARCHIVES

export const getCaseArchivePath = () => `${getLifetimeCasesPath()}archive/`;
export const getMortemCaseArchivePath = () => `${getMortemCasesPath()}archive/`;

// USERS

export const getUserPath = () => [getAPIEndpoint(), "user/"].join("/");
export const getUserByIdPath = (userId) => [getAPIEndpoint(), `user/${userId}/`].join("/");
export const postUserSessionPath = () => [getAPIEndpoint(), `session/`].join("/");

//CASSETTE DEFECT
export const samplePreparationCassetteDefectPath = () => [getAPIEndpoint(), "cassette/material/defect/"].join("/");
//MICROPREPARATION DEFECT
export const samplePreparationMicropreparationDefectPath = () => [getAPIEndpoint(), "micropreparation/material/defect/"].join("/");

//NSI PATH
export const getDictionariesNsiPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "nsi"].join("/");
export const getDictionariesDepartmentsPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "nsi/department"].join("/");
export const getDictionariesOrganizationsPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "nsi/organizations"].join("/");
export const getDictionariesPositionsPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "nsi/position"].join("/");
export const getDictionariesPaymentPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "nsi/payment"].join("/");
export const getDictionariesPlacementPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "nsi/placement"].join("/");
export const getDictionariesInsurancePath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "nsi/insurance"].join("/");
export const getDictionariesSubjectPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "nsi/subject"].join("/");

//PERMISSIONS
export const getSettingsReferencePermissionsPath = () => [getAPIEndpoint(),  "permission-settings/"].join("/");
export const postSettingsReferencePermissionsGroupPath = () => [getAPIEndpoint(),  "group/"].join("/");
export const patchSettingsReferencePermissionsGroupPath = (groupId) => [getAPIEndpoint(),  `group/${groupId}/`].join("/");
export const deleteSettingsReferencePermissionsGroupPath = (groupId, permissionId) => [getAPIEndpoint(),  `group/${groupId}/permission/${permissionId}/`].join("/");

//EMPLOYEES
export const getSettingsReferenceEmployeesPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "employee/"].join("/");
export const patchSettingsReferenceEmployeesPath = (employeeId) => `${getSettingsReferenceEmployeesPath()}${employeeId}/`;

//EMPLOYEES POSITION
export const getSettingsReferenceEmployeesPositionPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "position/"].join("/");
export const patchSettingsReferenceEmployeesPositionPath = (positionId) => `${getSettingsReferenceEmployeesPositionPath()}${positionId}/`;



// REFERENCES

export const getPatientByIPAPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "patient"].join("/");
export const getLifetimeCasesBiopsyMaterialPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "biopsy/material/"].join("/");

export const getReferenceIcdCodePath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "icd-code/"].join("/");
export const getReferenceIcdCodeOncologyPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "icd-code-oncology/"].join("/");
export const getReferenceLocalizationPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "biopsy/localization/"].join("/");
export const getReferenceResponsibleStaffMemberPath = () => [getAPIEndpoint(), "user/"].join("/");
export const getReferenceBiopsyPositionPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "biopsy/position/"].join("/");
export const getReferenceBiopsyGenderPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "gender/"].join("/");
export const getReferenceBiopsyFormPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "biopsy/form/"].join("/");
export const getReferencePositionPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "staff/position/"].join("/");
export const getMicropraparationColoringListPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "research_methods/coloring/"].join("/");
export const getSettingsReferenceColoringMethodsPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "research_methods/coloring_method/"].join("/");
export const getReferenceMedicalServicesPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "medical-services/"].join("/");

export const getReferenceOrganizationPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "organization"].join("/");
export const getReferenceOrganizationDepartmentPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "department/"].join("/");
export const patchReferenceOrganizationDepartmentPath = (departmentId) => [getAPIEndpoint(), getAPIEndpointDictionary(), `department/${departmentId}/`].join("/");
export const getReferenceOrganizationDepartmentDoctorPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "doctor/"].join("/");

export const getReferenceDefectsPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "defects/lifecase/"].join("/");
export const getReferenceFlasksDefectsPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "defects/flask/"].join("/");
export const getReferenceSamplePreparationDefectsPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "defects/cassette_and_micropreparation/"].join("/");
export const getReferenceSamplePreparationDefectsTypesPath = () => [getAPIEndpoint(), getAPIEndpointDictionary(), "defects/cassette_and_micropreparation_type/"].join("/");

// SETTINGS REFERENCES
export const getSettingsReferenceStaffPath = () => [getAPIEndpoint(), getAPIEndpointDictionarySettings(), "staff/person/"].join("/");

export const getSettingsReferenceOrganizationPath = () => [getAPIEndpoint(), getAPIEndpointDictionarySettings(), "organization/"].join("/");
export const patchSettingsReferenceOrganizationPath = (organizationId) => [getAPIEndpoint(), getAPIEndpointDictionarySettings(), `organization/${organizationId}/`].join("/");

export const getSettingsReferenceDepartmentPath = () => [getAPIEndpoint(), getAPIEndpointDictionarySettings(), "departments_list/"].join("/");
export const postSettingsReferenceDepartmentPath = (organizationId) => `${getSettingsReferenceOrganizationPath() + organizationId}/departments/`;
export const patchSettingsReferenceDepartmentPath = (organizationId, departmentId) => `${postSettingsReferenceDepartmentPath(organizationId) + departmentId}/`;

export const getSettingsReferenceDoctorPath = () => [getAPIEndpoint(), getAPIEndpointDictionarySettings(), "doctors_list/"].join("/");
export const postSettingsReferenceDoctorPath = (organizationId, departmentId) => `${patchSettingsReferenceDepartmentPath(organizationId, departmentId)}doctors/`;
export const patchSettingsReferenceDoctorPath = (organizationId, departmentId, doctorId) => `${postSettingsReferenceDoctorPath(organizationId, departmentId) + doctorId}/`;

export const getSettingsReferenceLocalizationPath = () => [getAPIEndpoint(), getAPIEndpointDictionarySettings(), "biopsy/localization/"].join("/");
export const getSettingsReferenceEquipmentPath = () => [getAPIEndpoint(), getAPIEndpointDictionarySettings(), "equipment/"].join("/");

export const getSettingsReferenceWiringProgramPath = () => [getAPIEndpoint(), getAPIEndpointDictionarySettings(), "wiring_program/"].join("/");
export const getSettingsReferenceDecaltinationAgentPath = () => [getAPIEndpoint(), getAPIEndpointDictionarySettings(), "decalcifying_agent/"].join("/");



export const getSettingsReferenceMaterialPath = () => [getAPIEndpoint(), getAPIEndpointDictionarySettings(), "biopsy/material/"].join("/");
export const getSettingsReferenceBiopsyTypePath = () => [getAPIEndpoint(), getAPIEndpointDictionarySettings(), "biopsy/type/"].join("/");
export const getSettingsReferenceDefectTypeMaterialPath = () => [getAPIEndpoint(), getAPIEndpointDictionarySettings(), "biopsy/defect/"].join("/");

// SETTINGS LIFETIME GENERAL
export const getSettingsLifetimeGeneralPath = () => [getAPIEndpoint(), getAPIEndpointSettings(), "global/"].join("/");
export const patchSettingsLifetimeGeneralPath = () => getSettingsLifetimeGeneralPath();

//SETTINGS REGISTRATION NUMBERS TEMPLATES
export const getSettingsRegistrationNumbersTemplatesPath = () => [getAPIEndpoint(), getAPIEndpointSettings(), "registration-number-template/"].join("/");
export const patchSettingsRegistrationNumbersTemplatesPath = (numberId) => [getAPIEndpoint(), getAPIEndpointSettings(), `registration-number-template/${numberId}/`].join("/");

//SETTINGS ORGANIZATION
export const getSettingsOrganizationPath = () => [getAPIEndpoint(), getAPIEndpointSettings(), "organization/"].join("/");

//SETTINGS PRODUCTION CALENDAR
export const getSettingsProductionCalendarPath = () => [getAPIEndpoint(), "production-calendar/"].join("/");
export const postSettingsProductionCalendarPath = () => [getAPIEndpoint(), "production-calendar/"].join("/");
export const getSettingsDaysOffInLawPath = (year) => [getAPIEndpoint(), `days-off-in-law/${year}/`].join("/");


// CHANGING STATUSES
export const patchLifetimeCaseStatusPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}status/update`;
// export const patchLifetimeCaseStatusSetWorkInProgressPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}status/set-wip`;
// export const patchLifetimeCaseStatusUnsetWorkInProgressPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}status/unset-wip`;
export const patchLifetimeCaseStatusUpdateRegisterPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}status/update_register`;
export const postLifetimeCaseStatusSetReceptionPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}status/set_reception`;
export const patchLifetimeCaseStatusUpdateReceptionPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}status/update_reception`;
export const postLifetimeCaseStatusSetMacroscopyPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}status/set_macroscopy`;
export const patchLifetimeCaseStatusUpdateMacroscopyPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}status/update_macroscopy`;
export const postLifetimeCaseStatusSetSamplePreparationPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}status/set_sample_preparation`;
export const patchLifetimeCaseStatusUpdateSamplePreparationPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}status/update_sample_preparation`;
export const patchLifetimeCaseStatusUpdateMicroscopyPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}status/update_microscopy`;
export const postLifetimeCaseStatusSetDonePath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}status/set_done`;
//DEFECTS
// export const postLifetimeCaseMaterialDefectPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}material/defect/`;
export const putLifetimeCaseMaterialDefectPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}material/defect/`;
export const deleteLifetimeCaseMaterialDefectPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}material/defect/`;

// export const postLifetimeCaseReferralDefectPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}referral/defect/`;
export const putLifetimeCaseReferralDefectPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}referral/defect/`;
export const deleteLifetimeCaseReferralDefectPath = (lifetimeCaseId) => `${getLifetimeCaseByIdPath(lifetimeCaseId)}referral/defect/`;

//PRINT
export const requestPdfGenerationPath = () => `${getAPIWebSocketEndpoint()}/ws/document/`;
export const requestLifeCasesJournalPath = () => `${getAPIWebSocketEndpoint()}/ws/lifecases_journal/`;
export const requestSamplePreparationJournalPath = () => `${getAPIWebSocketEndpoint()}/ws/sample_prep_journal/`;

//TIMEZONE
export const getSettingsTimezonePath = () => [getAPIEndpoint(), getAPIEndpointDictionarySettings(), "timezones/"].join("/");



