import { api } from "@/api";

export default {
    state: {
        settingsReferenceColoringPage: 1,
        settingsReferenceColoringPageSize: 50,
        settingsReferenceColorings: {},
        settingsReferenceColoringsTotalPages: null,
        isSettingsReferenceColoringsLoading: false,
    },
    actions: {
        async fetchSettingsReferenceColoring({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceColoringsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const colorings = await api.getSettingsReferenceColoring(queryParams, token);
                    commit("changeSettingsReferenceColorings", colorings.results);
                    commit("changeSettingsReferenceColoringsTotalPages", colorings.total_pages);
                    commit("updateSettingsReferenceColoringsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceColoringsLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async fetchMoreSettingsReferenceColorings({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferenceColoringsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const colorings = await api.getSettingsReferenceColoring(queryParams, token);
                    commit("updateMoreSettingsReferenceColorings", colorings.results);
                    commit("updateSettingsReferenceColoringsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferenceColoringsLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSettingsReferenceColoringsLoader", false);
            }

        },
        async addSettingsReferenceColorings({ dispatch, commit }, coloring ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const coloringData = await api.postSettingsReferenceColoring(coloring, token);
                    if (typeof coloringData === "object") {
                        commit("addNewSettingsReferenceColorings", coloringData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async editSettingsReferenceColorings({ dispatch, commit }, coloring ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const coloringData = await api.patchSettingsReferenceColoring(coloring, token);
                    if (typeof coloringData === "object") {
                        commit("updateSettingsReferenceColorings", coloringData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    mutations: {
        changeSettingsReferenceColorings(state, settingsReferenceColorings) {
            state.settingsReferenceColorings = settingsReferenceColorings;
        },
        updateMoreSettingsReferenceColorings(state, settingsReferenceColorings) {
            state.settingsReferenceColorings = [...state.settingsReferenceColorings, ...settingsReferenceColorings];
        },
        changeSettingsReferenceColoringsTotalPages(state, settingsReferenceColoringsTotalPages) {
            state.settingsReferenceColoringsTotalPages = settingsReferenceColoringsTotalPages;
        },
        updateSettingsReferenceColoringsLoader(state, condition) {
            state.isSettingsReferenceColoringsLoading = condition;
        },
        updateSettingsReferenceColorings(state, biobsyType) {
            const index = state.settingsReferenceColorings.findIndex(item => item.id === biobsyType.id);
            if (index !== -1) {
                const updatedColoringMethod = { ...biobsyType }; // Создаем копию объекта biobsyType
                const uniqueId = Object.values(updatedColoringMethod).join('_'); // Создаем уникальный идентификатор на основе значений объекта
                updatedColoringMethod.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
                state.settingsReferenceColorings.splice(index, 1, updatedColoringMethod); // Заменяем объект в массиве
            }
        },
        addNewSettingsReferenceColorings(state, biobsyType) {
            const updatedColoringMethod = { ...biobsyType }; // Создаем копию объекта biobsyType
            const uniqueId = Object.values(updatedColoringMethod).join('_'); // Создаем уникальный идентификатор на основе значений объекта
            updatedColoringMethod.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
            state.settingsReferenceColorings.unshift(updatedColoringMethod);
        },
    },
    getters: {
        getSettingsReferenceColorings(state) {
            return state.settingsReferenceColorings;
        },
        getSettingsReferenceColoringsTotalPages(state) {
            return state.settingsReferenceColoringsTotalPages;
        },
        getSettingsReferenceColoringsLoaderStatus(state) {
            return state.isSettingsReferenceColoringsLoading;
        },
    }
}