import { api } from "@/api";


export default {
    state: {
        samplePreparationCassetteDefect: null,
        samplePreparationMicropreparationDefect: null,
        isSamplePreparationDefectCanceled: false,
    },
    actions: {
        async addSamplePreparationCassetteDefect({ dispatch, commit }, defectData) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const defect = await api.postAddSamplePreparationCassetteDefect(defectData, token);
                    if (typeof defect === "object") {
                        commit("updateSamplePreparationCassetteDefect", defect);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        // eslint-disable-next-line no-useless-catch
        async cancelSamplePreparationCassetteDefect({ dispatch, commit }, queryParams) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const canceledDefect = await api.deleteSamplePreparationCassetteDefect(queryParams, token);
                    if (canceledDefect.status === 204) {
                        commit("updateSamplePreparationDefectCanceled");
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
                throw error;
            }
        },
        async addSamplePreparationMicropreparationDefect({ dispatch, commit }, defectData) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const defect = await api.postAddSamplePreparationMicropreparationDefect(defectData, token);
                    if (typeof defect === "object") {
                        commit("updateSamplePreparationMicropreparationDefect", defect);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        // eslint-disable-next-line no-useless-catch
        async cancelSamplePreparationMicropreparationDefect({ dispatch, commit }, queryParams) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const canceledDefect = await api.deleteSamplePreparationMicropreparationDefect(queryParams, token);
                    if (canceledDefect.status === 204) {
                        commit("updateSamplePreparationDefectCanceled");
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
                throw error;
            }
        },
    },
    mutations: {
        updateSamplePreparationCassetteDefect(state, defect) {
            state.samplePreparationCassetteDefect = defect;
        },
        updateSamplePreparationMicropreparationDefect(state, defect) {
            state.samplePreparationCassetteDefect = defect;
        },
        updateSamplePreparationDefectCanceled(state) {
            state.isSamplePreparationDefectCanceled = !state.isSamplePreparationDefectCanceled;
        }
    },
    getters: {
        getSamplePreparationCassetteDefect(state) {
            return state.samplePreparationCassetteDefect;
        },
        getSamplePreparationMicropreparationDefect(state) {
            return state.samplePreparationMicropreparationDefect;
        },
        getSamplePreparationDefectCanceled(state) {
            return state.isSamplePreparationDefectCanceled;
        }
    }
}