import { api } from "@/api";

export default {
    state: {
        isDefectGroupDirection: false,
        isDefectGroupMaterial: false,
        editLifetimeDefectStatus: false,
        defectCategoriesTransformedById: {},
        defectCategoriesDictionary: [],
        defectCategoriesPartial: [],
        defectCategoriesPartialTransformedById: {},
        defectCategoriesPartialData: [],
        defectCassetteCategoriesData: [
            "Брак Декальцинации",
            "Брак Дофиксации",
            "Брак Проводки",
            "Брак Заливки"

        ],
        defectCassetteMicroData: [
            "Брак Микротомии",
            "Брак Окраски",
            "Брак Проводки",

        ]
    },
    actions: {
        async fetchDefectCategories({ commit, dispatch }) {
            const token = await dispatch("fetchAccessToken");
            try {
                const queryParams = {
                    active: true,
                    page_size: 1000,
                    page: 1
                };
                const defectCategories = await api.getReferenceDefects(queryParams, token);
                commit("changeDefectCategoriesDictionary", defectCategories.results);
                commit("changeDefectCategoriesTransformedById", defectCategories.results);
            } catch (e) {
                console.error(e);
                return e;
            }
        },
        async fetchDefectCategoriesPartial({ commit, dispatch }) {
            const token = await dispatch("fetchAccessToken");
            try {
                const queryParams = {
                    active: true,
                    page_size: 1000,
                    page: 1
                };
                const defectFlasksCategories = await api.getReferenceFlasksDefects(queryParams, token);
                defectFlasksCategories.results.unshift(
                    {
                        "id": null,
                        "active": true,
                        "title": "Отсутствует",
                        "default_value": "EQUAL",
                        "comment": ""
                    }
                )
                commit("changeDefectCategoriesPartial", defectFlasksCategories);
                commit("changeDefectCategoriesPartialTransformedById", defectFlasksCategories.results);
            } catch (e) {
                console.error(e);
                return e;
            }
        },
        updateIsDefectGroupDirection({ commit }, status) {
            commit("changeIsDefectGroupDirection", status);
        },
        updateIsDefectGroupMaterial({ commit }, status) {
            commit("changeIsDefectGroupMaterial", status);
        },
    },
    mutations: {
        changeDefectCategoriesTransformedById(state, defectCategories) {
            const defectCategoriesTransformedById = {};
            defectCategories.map((category) => {
                defectCategoriesTransformedById[category.id] = {
                    ...category
                }
            })
            state.defectCategoriesTransformedById = defectCategoriesTransformedById;
        },
        changeDefectCategoriesPartialTransformedById(state, defectCategories) {
            const defectCategoriesPartialTransformedById = {};
            defectCategories.map((category) => {
                defectCategoriesPartialTransformedById[category.id] = {
                    ...category
                }
            })
            state.defectCategoriesPartialTransformedById = defectCategoriesPartialTransformedById;
        },
        changeDefectCategoriesDictionary(state, defectCategories) {
            state.defectCategoriesDictionary = defectCategories;
        },
        changeDefectCategoriesPartial(state, defectCategoriesPartial) {
            state.defectCategoriesPartial = defectCategoriesPartial;
        },
        changeIsDefectGroupDirection(state, status) {
            state.isDefectGroupDirection = status;
        },
        changeIsDefectGroupMaterial(state, status) {
            state.isDefectGroupMaterial = status;
        },
    },
    getters: {
        getDefectCategoriesTransformedById(state) {
            return state.defectCategoriesTransformedById;
        },
        getDefectCategoriesPartialTransformedById(state) {
            return state.defectCategoriesPartialTransformedById;
        },
        getDefectCassetteCategoriesData(state) {
            return state.defectCassetteCategoriesData;
        },
        getDefectCassetteMicroData(state) {
            return state.defectCassetteMicroData;
        },
        getDefectCategoriesPartial(state) {
            return state.defectCategoriesPartial;
        },
        getIsDefectGroupDirection(state) {
            return state.isDefectGroupDirection;
        },
        getIsDefectGroupMaterial(state) {
            return state.isDefectGroupMaterial;
        },
        getDefectCategoriesDictionary(state) {
            return state.defectCategoriesDictionary;
        },
    }
}